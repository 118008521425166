.header-logo {
    position: relative;
    z-index: 1;
    top: 5px;
}

.header-logo img {
    width: 135px;
    padding-bottom: 10px;
}

.header-top ul {
    margin: 0;
    padding: 0;
}

.header-top ul li {
    display: inline-block;
    padding: 5px 15px;
}

.header-top ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    position: relative;
    top: 3px;
}

.mainheader {
	background:none;
    background-image: url(../images/header-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
	position: relative;
    z-index: 9;
    width: 100%;
}
/*
.mainHeader-top {
background: linear-gradient(to right,  #ffffff 29%,#1c2c45 29%);
} */

.header-top::before {
    content: '';
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+29,1c2c45+29 */
    background: linear-gradient(to right, #ffffff00 33%, #0a2550 33%);
    position: absolute;
    width: 100%;
    height: 40px;
    left: 0;
    right: 0;
}

section {
    padding: 50px 0px;
}

/*---------------------------------main-section-start---------------------------------*/

.keyfeatures-list ul {
    margin: 0;
    padding: 0;
}

.keyfeatures-list ul li {
    text-align: left;
    list-style: none;
    line-height: 30px;
    color:#fff;
    font-size: 1.1rem;
}

.keyfeatures-list ul li .fa-check-circle {
    margin-right: 10px;
    color: #fff;
}

.services-section {
    text-align: center;
    margin-top: -8px !important;
    background-color: #fff;
}

.services-details {
    background-color: #fff;
    border-radius: 10px;
    padding: 45px 0 30px;
    position: relative;
    z-index: 1;
    margin-bottom: 95px;
    margin-top: 10px;
    color: #1c2c45;
    font-size: 20px;
}

.services-text p {
	    position: relative;
    z-index: 99;
}

.services-text p span {
    font-weight: 800;
    font-size: 2rem;
}

.services-icon {
    background: #fff;
    width: 40%;
    margin: 0 auto;
    border-radius: 100px;
    padding: 25px;
    height: auto;
    z-index: 99;
    position: absolute;
    left: 0;
    right: 0;
    top: -66px;
}

.online-skills {
    text-align: center;
    background-color: #fff;
}

.online-skills h2 {
    font-size: 45px;
    text-transform: uppercase;
    color: #1c2c45;
    font-weight: 800;
    margin: 0;
    display: inline-block;
}

.online-skills .subtitle {
    font-size: 17px;
    color: #6d6d6d;
    text-transform: capitalize;
    font-weight: normal;
    margin-top: 15px;
}

.bg {
    background-image: url(../images/box-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
}

.bg-bluecolor {
    background-color: #0f293a;
    border: #47839b solid 2px;
    border-radius: 10px;
}

.services-details.active {
	background: #1ea57a;
	color:#fff;
}

.services-details.active .services-icon {
	background: #1ea57a;
}

.swiper-button-prev:after, .swiper-button-next:after {
	color:#fff;
}

.about-section {
    font-size: 0.9rem;
}

.title-gradient {
background: #012648;
background: linear-gradient(to right, #012648 0%, #2dc7df 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.service-title h3 {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
	margin-top:6px !important;
}

.service-title {
    margin-bottom: 10px;
}

.service-title .subtitle {
	color: #0e1d60;
    font-size: 1rem;
    font-weight: 500;
}

.online-class a {
    color: #000;
    text-decoration: none;
    font-weight: 600;
}
.subtitle {
    color: #f0af17;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
}
.block-title {
    font-weight: 700;
    font-size: 2rem;
}

.common-btn {
    background-color: #7fdbff;
    border-radius: 50px;
    border: #7fdbff solid 2px;
    padding: 6px 40px 6px 30px;
    font-size: 0.9rem;
    color: #000;
    transition: all 0.5s;
    position: relative;
    white-space: nowrap;
    text-decoration: none;
  }

  .common-btn:after {
    content: "\f061";
    font: normal normal normal 14px/1 FontAwesome;
    font-weight: 600;
    position: absolute;
    left: 78%;
    top: 32%;
    right: 5%;
    bottom: 0;
    opacity: 1;
    color: #000;
  }
  .common-btn:hover {
    background: #17aa6d;
    transition: all 0.5s;
    padding: 6px 3.5rem 6px 3.5rem;
    color: #fff;
  }
  .common-btn:hover::after {
    opacity: 1;
    transition: all 0.5s;
    color: #fff;
  }

  .about-section h4 {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 31px;
  }

.main {
    background-color: #fff;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #0f293a;
    background-color: #fff;
    border-radius: 50px;
    font-size: 1.1rem;
    font-weight: 600;
}

.price-plan .nav-pills .nav-link.active, .price-plan .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0f293a;
    border-radius: 50px;
    font-size: 1.1rem;
    font-weight: 600;
}

.nav-pills .nav-link {
    color: #fff;
    font-weight: 700;
    font-size: 1.1rem;
}

.price-plan .nav-pills .nav-link {
    color: #0f293a;
}


/* .consultant-section { background-image:url(../images/consultant-bg.jpg); background-repeat:no-repeat; background-position:center center; background-size:cover; width:100%; color:#fff; overflow:inherit !important; background-attachment:fixed; } */
.consultant-header h3 { font-size:2.5rem; }
.consultant-header p { font-size:1.8rem; }
.consultant-header p span { color:#ffc600; padding:0px 10px; }
.consultant-para { font-size:1.3rem; padding:80px 50px 80px 50px; }
.consultant-details { position: relative;}
.consultant-photo { position:relative; bottom: -60px; z-index: 1; }
.photo-brief { position: absolute; bottom: 0px; left: 25px; right: 25px;  }
.photo-brief .con-brief { color:#fff; text-transform: uppercase;
font-size: 2.2rem; text-align: left; line-height: 40px; font-weight: 800; }
.photo-brief .con-brief span { color:#e20613; }
.consultant-para h2 { margin-bottom: 60px; font-size: 3rem; }
.consultant-para .section-button { background:none; color:#fff; }
.consultant-name { color:#b1b1b1; font-size:1.5rem; }
.consultant-photo .zoom-effect, .consultant-details .zoom-effect { transition: 0.5s all ease-in-out; position:relative; }
.consultant-photo .zoom-effect:hover, .consultant-details:hover .zoom-effect { transform: scale(1.1); }

.consultant-section .common-btn {
    border: #fff solid 1px;
    background: none;
    color: #fff;
}

.consultant-section .common-btn:after {
    left: 85%;
    top: 32%;
}

.testimonial-girl {
    background-color: #e7c021;
    color: #000;
    min-height: 340px;
}

.testimonial-boy {
    background-color: #fff;
    color: #000;
}

.testimonial-profile {
    display: flex;
    align-items: center;
}

.profile-content {
    margin-left: 15px;
    font-size: 1rem;
    color: #000;
}

.testimonial-boy .profile-content {
    margin-left: 15px;
    font-size: 1rem;
    color: #000;
}

.blog-section { position: relative;}
.blog-content { background-color: #f7f7f7; padding: 15px;}
.blog-content h2 { font-size: 1.4rem; font-weight: 600;}

.bg-lightblue {
    background-color: #cde4ea;
    border: #092A4A solid 1px;
    overflow: hidden;
    border-radius: 5px;
}


/* Features Section Five */

.features-section-five {
    position: relative;
    padding: 100px 0;
	background:#fff;
}

.features-section-five .link-btn {
    position: relative;
    text-align: center;
    margin-top: 30px;
}

.feature-block-five .inner-box {
    position: relative;
    padding: 60px 35px 35px;
    padding-right: 28px;
    max-width: 270px;
    margin: 0 auto 30px;
}

.feature-block-five .inner-box:before {
    position: absolute;
    content: '';
    left: -42px;
    top: -40px;
    right: -42px;
    bottom: -45px;
    /* background-image: url(../images/shape-10.png); */
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.feature-block-five:nth-child(even) .inner-box:before {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.feature-block-five .icon {
    margin-bottom: 25px;
    position: relative;
}

.feature-block-five h4 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
	position:relative;
	min-height:85px;
}

.feature-block-five .text {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}

.feature-block-five .read-more-link {
    position: absolute;
    top: 42px;
    right: 22px;
    height: 57px;
    width: 57px;
    line-height: 57px;
    text-align: center;
    border-radius: 50%;
    color: #f0af17;
    font-size: 35px;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    opacity: 0;
    -webkit-transform: scale(.5);
        -ms-transform: scale(.5);
            transform: scale(.5);
}

.feature-block-five .inner-box:hover .read-more-link {
    opacity: 1;
    -webkit-transform: scale(1) rotate(360deg);
        -ms-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
}

.features-section-five .theme-btn {
    border-radius: 32px;
    background-color: transparent;
}

.features-section-five .btn-title {
    padding: 13px 45px;
    border-radius: 30px;
    color: #0c2957;
    background-color: #ebf0f9;
    text-transform: uppercase;
}

.features-section-five .theme-btn:hover .btn-title {
    color: #fff;
}

.features-section-five .theme-btn .btn-title i {
    font-size: 18px;
    margin-left: 8px;
    line-height: 1.4em;
    top: 2px;
    margin-right: 0;
}

.taxgpt-content {
    color: #bfbfbf;
}

.solution-section {
	background:#fff;
}

.solution-content {
	position:relative;
}

.solution-content h4 {
	color:#fff;
    font-weight: 600;
}

.btnview-details {
	color:#fff;
	font-size:1rem;
	text-decoration:none;
}

.solution-section h2 {
	color: #193097;
    transform: rotate(-90deg);
    height: 100%;
    position: relative;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    font-weight: 800;
	}

.zoom-effect { transition: 0.5s all ease-in-out; position:relative; overflow:hidden; }
.zoom-effect:hover { transform: scale(1.1); }

.features-section-five h2 {
    font-size: 40px;
    text-transform: uppercase;
    color: #1c2c45;
    font-weight: 800;
    margin: 0;
}

.how-towork {
    /* background-color: #f5f5f5; */
}


.faq-bg {
    background-image: url(../images/faq-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
}

.accordion-button {
    background: none;
    padding: 1.5rem 1.75rem;
    font-size: 1.2rem;
}

.faqbg-bluecolor {
    background-color: #0f293a;
    border: #47839b solid 2px !important;
    border-radius: 10px !important;
    color: #fff;
    margin-bottom: 8px;
    overflow: hidden;
}

.accordion-body {
    padding-top: 0;
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "\f107";
    font: normal normal normal 14px/1 FontAwesome;
    background-image: inherit;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    color: #fff;
}

.accordion-button:not(.collapsed)::after {
    background-image: inherit;
    transform: rotate(-180deg);
}

.how-towork h2, .price-plan h2 {
    font-size: 40px;
    text-transform: uppercase;
    color: #1c2c45;
    font-weight: 800;
    margin: 0;
    text-align: center;
}

.how-towork h2 {
    display: inline-block !important;
    width: auto;
    margin: 0 auto;
}

.howtobox-col {
    padding: 0px 25px;
}

.howtobox-col h3 {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 24px;
    font-weight: 700;
}

.howto-content {
    font-size: 0.9rem;
    line-height: 21px;
}

.howicon {
    background-color: #17a96c;
    padding: 15px;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 15px;
}

.faq-section {
    /* background-image: url(images/flyer-bg.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
    position: relative;
}

.faq-row {
    background-color: #fff;
    text-align: center;
}

.accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #0f293a;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}


.subscription-row {
    background-color:#1d1d1f;
}

.subscribe {
    background-color: #fff;
    padding: 4px;
    border-radius: 50px;
    overflow:hidden;
}

.subscribe-1 {
    position: relative;
    width: 100%
}

.subscribe-1 input {
    height: 45px;
    border: none;
    width: 100%;
    padding-left: 34px;
    padding-right: 10px;
    border-right: 2px solid #eee
}

.subscribe-1 input:focus {
    border-color: none;
    box-shadow: none;
    outline: none
}

.subscribe-1 i {
    position: absolute;
    top: 12px;
    left: 5px;
    font-size: 24px;
    color: #eee
}

::placeholder {
    color: #eee;
    opacity: 1
}

.subscribe-1 button {
    position: absolute;
    right: 1px;
    top: 0px;
    border: none;
    height: 45px;
    background-color: #fac817;
    color: #000;
    width: 90px;
    border-radius: 50px;
}

@media (max-width:800px) {

    .subscribe-1 input {
        border-right: none;
        border-bottom: 1px solid #eee
    }

    .subscribe-1 button {
        height: 37px;
        top: 5px
    }
}

.subscription-row h2 {
    color: #fac817;
}

.whitebg {
    background-color: #fff;
}

.about-content h2 {
    font-size: 30px;
    text-transform: uppercase;
    color: #1c2c45;
    font-weight: 800;
    margin: 0;
    line-height: 40px;
}


/***-------------------------------------
Price Plan
------------------------------------------***/

.price-plan .bg-light {
    background-color: #7fdbff !important;
    color: #092a4b;
    border: #092a4b solid 1px;
}

.table.table-lg th {
    color: #092a4b;
    font-size: 1.1rem;
}


.price-plan .bg-yellow {
    background-color: #f9c618 !important;
    color: #000;
}

.hw-18 {
    height: 18px!important;
    min-width: 18px!important;
    width: 18px!important;
}
.rounded-circle {
    border-radius: 50%!important;
}
.border {
    border: 1px solid #cbd5e1!important;
}
.badge-light {
    color: #101729;
    background-color: #f1f4f9;
}
.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.say-yes:before {
    content: "";
    vertical-align: middle;
    display: inline-block;
    background-image: url("../images/arrow.png");
    background-repeat: no-repeat;
    background-position: center center;
    width: 18px;
    height: 14px;
}
.say-yes, .say-yes:before {
    height: 1.5rem;
    width: 1.5rem;
}

.say-yes {
    position: relative;
    display: block;
    margin: 0 auto;
}
.btn-white {
    border: 1px solid #cbd5e1;
    background-color: #fff;
}

.mt-3, .my-3 {
    margin-top: 1rem!important;
}
.btn-group-sm>.btn, .btn-sm {
    padding: .7rem 1.55rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .25rem;
}
.table.table-lg td, .table.table-lg th {
    padding: 1rem .75rem;
}
.table.table-lg td, .table.table-lg th {
    padding: 1rem .75rem;
}
.table-borderless tbody+tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
    border: 0;
}


.price-plan {
    background-color: #fff	;
}

.faq-section {
    background-color: #fff;
}

.faq-section h3 {
    font-size: 40px;
    text-transform: uppercase;
    color: rgb(28, 44, 69);
    font-weight: 800;
    margin: 0px;
    text-align: center;
}

a:hover, button:hover {
    color: #17a96c;
}

.accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
    font-weight: 600;
}

.internal-headercontent {
    position: absolute;
    z-index: 9;
    top: 45%;
    left: 10%;
    color: #fff;
}

.internal-headercontent h1 {
    font-size: 3.2rem;
    font-weight: 700;
}

.internal-headercontent h1 span {
    color: #75c7f0;
}

.internal-headercontent p {
    font-size: 1.8rem;
    color: #75c7f0;
}

.internal-content h2 {
    font-size: 40px;
    text-transform: uppercase;
    color: #1c2c45;
    font-weight: 800;
    margin: 0;
}

.graybg {
    background-color: #f2f0f5;
}

.login-page {
    background-image: url("../images/login-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}


/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
    background-color: #001f3e;
    color: #fff;
    font-size: 14px;
    box-shadow: 0 0rem 6rem rgba(0,0,0,.175)!important;
  }


  #footer .footer-top .footer-info {
    margin-bottom: 30px;
  }

  #footer .footer-top .footer-info h3 {
    font-size: 28px;
    margin: 0 0 15px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }

  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    color: #fff;
  }

  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.08);
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }

  #footer .footer-top .social-links a:hover {
    background: #ff4a17;
    color: #fff;
    text-decoration: none;
  }

  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }

   #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #fff;
    font-size: 18px;
    line-height: 1;
  }

  #footer .footer-top .footer-links ul li {
    padding: 10px 15px;
    display: block;
    width: 100%;
    text-align: left;
}


  #footer .footer-top .footer-links ul a {
    color: #7fdcfe;
    text-decoration: none;
  }

  #footer .footer-top .footer-links ul a:hover {
    color: #fff;
  }

  #footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
  }

  #footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
  }

  #footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #ef4036;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
  }

  #footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #ff5e31;
  }

  #footer .copyright {
    text-align: center;
    background-color: #e7e3e3;
    padding: 10px;
    color: #000;
  }

  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }

  #footer .credits a {
    transition: 0.3s;
  }

  .footer-links address { color: #fff; position: relative; padding-left: 20px; }
  .footer-links address .bx { position: absolute; left: 0; top: 5px;}
  .footerbot-link { margin: 0; padding: 0; padding-top: 25px; }
  .footerbot-link li { display: inline-block; list-style: none;}
  .footerbot-link li a { color: #fff; border-right: #000 solid 1px; padding: 0px 15px;}
  .footerbot-link li:last-child a { border: none;}

  .stellarnav > ul > li.signin-btn a {
    background: #ccf2ff;
    color: #000;
    border-radius: 50px;
    padding: 5px 20px;
  }

  .stellarnav > ul > li.signin-btn a:hover {
    color: #000;
    background-color: #17aa6d;
  }

  .solutions-thumbnail img {
    width: 70px;
}

.solutions-thumbnail {
    min-height: 310px;
}

.title-icon { position: relative;}

.title-icon::before {
    content: '';
    background-image: url(../images/title-icon.png);
    width: 96px;
    height: 12px;
    position: absolute;
    left: 0;
    bottom: -15px;
}


@media (max-width:767px){

    .header {
        text-align:center;
    }
    .header-logo img {
        padding-bottom: 10px;
    }

   .online-skills .online-class {
    background: #fff;
    margin-bottom: 25px;
}

.about-details {
    margin-top: 15px;
}

.photo-brief .con-brief {
    font-size: 1.9rem;
    text-align: left;
    line-height: 34px;
    font-weight: 800;
}

.consultant-para h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    margin-top: 2rem;
}

.consultant-para {
    font-size: 1.2rem;
    padding: 80px 10px 80px 10px;
}

.services-icon {
    width: 100px;
    left: 0;
    right: 0;
    top: -46px;
}

.solution-section .solutions-thumbnail {
    text-align: center;
    margin-bottom: 50px;
}

.how-towork h2, .price-plan h2 {
    font-size: 28px;
}
.howtobox-col { margin-bottom: 50px; }

.faq-section h3 {
    font-size: 25px;
    line-height: 32px;
}

.footer-links {
    text-align: center !important;
    width: 100%;
}

}

.border-mid::before {
    content: '';
    background-color: #000;
    width: 61%;
    height: 2px;
    position: absolute;
    left: -118px;
    top:90px;
}

.stellarnav > ul {
    margin-top: 20px;
}

@media( max-width:767px ){
    .border-mid::before { display: none;}
    .services-details { margin-bottom: 50px; padding: 0;}
    .services-text { margin-top: 0;}
    .nav-item, .nav-pills .nav-link { width: 100%;}
    .online-skills h2 { line-height: 50px;}
}

/* .border-mid::after {
    content: '';
    background-color: #000;
    width: 61%;
    height: 2px;
    position: absolute;
    right: -118px;
    top:90px;
} */

/*---------------------------------main-section-stop---------------------------------*/

