/* ==============
 ========= css documentation =========

 * theme name: Aikeu
 * version: 1.0
 * description: Artificial Neural Network AI HTML Template
 * author: Pixelaxis
 * author-url: https://themeforest.net/user/pixelaxis

 01. abstracts
     1.01 --> mixins
     1.02 --> variables

 02. base
     2.01 --> reset
     2.02 --> typography
     2.03 --> global

 03. components
     3.01 --> buttons
     3.02 --> forms
     3.03 --> preloader

 04. layout
     4.01 --> header
     4.02 --> banner
     4.03 --> footer

 05. sections
     5.01 --> text slider styles
     5.02 --> craft section styles
     5.03 --> text slider large styles
     5.04 --> tools section styles
     5.05 --> gen section styles
     5.06 --> partner section styles
     5.07 --> service horizontal slider styles
     5.08 --> unlock section styles
     5.09 --> overview section styles
     5.10 --> video section styles
     5.11 --> pricing section styles
     5.12 --> blog one section styles
     5.13 --> counter section styles
     5.14 --> overview two styles
     5.15 --> review styles
     5.16 --> text brief styles
     5.17 --> revolution styles
     5.18 --> empower section styles
     5.19 --> gaming section styles
     5.20 --> pricing secondary section styles
     5.21 --> showcase section styles
     5.22 --> product filter section styles
     5.23 --> category section styles
     5.24 --> new prompts section styles
     5.25 --> feature seller section styles
     5.26 --> n-level section styles
     5.27 --> revolution five section styles
     5.28 --> home five chatbot section styles
     5.29 --> home five unlock section styles
     5.30 --> blog alter section styles
     5.31 --> news alter section styles
     5.32 --> publisher section styles
     5.33 --> home six poster section styles
     5.34 --> seven journey section styles
     5.35 --> home seven empower section styles
     5.36 --> home seven case study section styles
     5.37 --> service main section styles
     5.38 --> service newsletter section styles
     5.39 --> main news section styles
     5.40 --> blog details section styles
     5.41 --> shop section styles
     5.42 --> product details section styles
     5.43 --> contact section styles
     5.44 --> case study main section styles
     5.45 --> case details styles
     5.46 --> authentication section styles
     5.47 --> cart section styles
     5.48 --> checkout section styles
     5.49 --> easy section styles
     5.50 --> case slider section styles

 06. responsive

    ==================================
============== */
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&amp;family=Montserrat:wght@100;200;300;400;500;600;700;800;900&amp;family=Poppins:wght@100;200;300;400;500;600;700;800;900&amp;display=swap"); */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --inter: "Inter", sans-serif;
  --mont: "Montserrat", sans-serif;
  --poppins: "Poppins", sans-serif;
  --theme-font: var(--inter);
  --theme-bg: #030015;
  --theme-color: #ffffff90;
  --white: #ffffff;
  --black: #000000;
  --primary-color: #f9c613;
  --secondary-color: #f9c613;
  --tertiary-color: #120f23;
  --quaternary-color: #191b1a;
  --quinary-color: #b1b0b6;
  --transition: all 0.3s ease-in-out;
  --shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  --shadow-secondary: 0 0 31px rgba(0, 0, 0, 0.1);
}

/* ====
 --------- (2.01) reset styles start ---------
 ==== */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

*::-moz-selection {
  color: #ffffff;
  background-color: #1770c8;
}

*::selection {
  color: #ffffff;
  background-color: #1770c8;
}

html {
  scroll-behavior: smooth;
}


body {
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  border-radius: 5px;
}

body::-webkit-scrollbar-button,
body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 5px;
}

.body-active {
  height: 100vh;
  overflow: clip;
}

.my-app {
  position: relative;
  min-height: 100vh;
  max-height: 100vh;
  overflow-x: clip;
}

button {
  background-color: transparent;
  border: 0px;
  outline: 0px;
}

a,
button {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  outline: 0px;
  border: 0px;
  transition: var(--transition);
  cursor: pointer;
}

a i,
button i {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

a:hover,
button:hover {
  text-decoration: none;
  border: 0px;
  outline: 0px;
}

a:focus,
button:focus {
  box-shadow: none;
  outline: 0px;
}

ul {
  list-style-type: circle;
  list-style-position: inside;
  margin: 0px;
  padding: 0px;
}


ol {
  list-style-type: decimal;
  list-style-position: inside;
  margin: 0px;
  padding: 0px;
}

hr,
blockquote,
textarea {
  margin: 0px;
  opacity: 1;
}

input,
textarea {
  border: 0px;
  outline: 0px;
}

input:focus,
textarea:focus {
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0px;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=checkbox] {
  width: initial;
  height: initial;
}

textarea {
  min-height: 150px;
  resize: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
}

iframe {
  border: 0px;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  font-family: var(--inter);
  padding: 0px;
  margin: 0px;
  /* color: var(--theme-color); */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--inter);
}

/* ====
     --------- (2.01) reset styles end ---------
     ==== */
/* ====
 --------- (2.02) typography styles start ---------
 ==== */
p,
th,
td,
li,
input,
textarea,
select,
label,
blockquote,
span {
  /* line-height: 24px; */
  line-height: 1.8rem;
  font-weight: 400;
  /* color: #fff; */
}

.chat { color: #fff;}

.primary-text {
  font-size: 24px;
  line-height: 34px;
}

.tertiary-text {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

a,
button {
  font-size: 18px;
  line-height: 24px;
  color: var(--theme-color);
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  gap: 8px;
}

a:hover,
button:hover {
  color: var(--primary-color);
}

h1 {
  font-size: 80px;
  line-height: 90px;
}

.light-title {
  font-size: 30px;
  line-height: 82px;
}

.light-title-sm {
  font-size: 56px;
  line-height: 66px;
}

.light-title-xs {
  font-size: 48px;
  line-height: 58px;
}

h2 {
  font-size: 40px;
  line-height: 50px;
}

h3 {
  font-size: 32px;
  line-height: 42px;
}

h4 {
  font-size: 24px;
  line-height: 34px;
}

h5 {
  font-size: 18px;
  line-height: 28px;
}

h6 {
  font-size: 16px;
  line-height: 24px;
}

.l-t {
  font-size: 140px;
  line-height: 150px;
  color: #c0bfc5;
  font-weight: 800;
  text-align: center;
  margin-top: -25px;
}

h1 a,
h1 span,
h2 a,
h2 span,
h3 a,
h3 span,
h4 a,
h4 span,
h5 a,
h5 span,
h6 a,
h6 span,
p a,
p span {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
}

.title-animation {}

.title-animation>div>div:first-child {
  text-transform: uppercase;
}

.title-animation>span>div>div:first-child {
  text-transform: uppercase !important;
}

.title-animation>span * {
  background-clip: inherit;
  -webkit-text-fill-color: inherit;
  background: inherit;
}

/* ====
 --------- (2.02) typography styles end ---------
 ==== */
/* ====
 --------- (2.03) global styles start ---------
 ==== */
img {
  max-width: 100%;
  height: auto;
  border: 0px;
  outline: 0px;
  object-fit: cover;
}

i {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

span {
  display: inline-block;
}

.cursor-outer {
  margin-left: -12px;
  margin-top: -12px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  z-index: 10000000;
  opacity: 0.5;
  transition: all 0.14s ease-out;
  mix-blend-mode: difference;
}

.cursor-outer.cursor-hover {
  opacity: 0;
  transition: none;
}

.cursor-outer.cursor-big {
  opacity: 0;
}

.mouseCursor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  border-radius: 50%;
  transform: translateZ(0);
  visibility: hidden;
  text-align: center;
}

.mouseCursor.cursor-big {
  width: 20px;
  height: 20px;
  margin-left: -12px;
  margin-top: -12px;
}

.cursor-inner {
  margin-left: -3px;
  margin-top: -3px;
  width: 10px;
  height: 10px;
  z-index: 10000001;
  background-color: var(--primary-color);
  opacity: 0.8;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  mix-blend-mode: difference;
}

.cursor-inner span {
  color: var(--theme-color);
  line-height: 60px;
  opacity: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
}

.cursor-inner.cursor-big span {
  opacity: 1;
}

.cursor-inner.cursor-hover {
  margin-left: -10px;
  margin-top: -10px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 1px solid #686363;
  opacity: 0;
}

.unset {
  max-width: unset;
}

.dir-rtl {
  direction: rtl;
}

.bg-img {
  background-color: var(--theme-color);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.slick-slide {
  margin: 0px 12px;
}

.slick-slide img {
  display: inline-block;
}

.slick-list {
  margin: 0px -12px;
}

.fw-9 {
  font-weight: 900 !important;
}

.fw-7 {
  font-weight: 700;
}

.fw-6 {
  font-weight: 600;
}

.fw-5 {
  font-weight: 500;
}

.fw-4 {
  font-weight: 400;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--secondary-color);
}

.text-tertiary {
  color: var(--tertiary-color);
}

.text-quaternary {
  color: var(--quaternary-color);
}

.text-quinary {
  color: var(--quinary-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-tertiary {
  background-color: var(--tertiary-color);
}

.bg-quaternary {
  background-color: var(--quaternary-color);
}

.bg-quinary {
  background-color: var(--quinary-color);
}

.gaper {
  row-gap: 24px;
}

.section {
  padding: 160px 0px;
  overflow-x: clip;
  position: relative;
  z-index: 1;
}

.section__cta {
  margin-top: 60px;
  text-align: center;
}

.section__content-cta {
  margin-top: 40px;
}

.cta-secondary {
  margin-top: 120px;
}

.banner__content {
  margin-top: -8px;
  text-align: center;
}

.banner__content h6 {
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-bottom: 16px;
  color: var(--theme-color);
  margin-bottom: 22px;
}

.banner__content h1 {
  font-weight: 700;
  color: var(--theme-color);
  margin-bottom: 16px;
  text-transform: capitalize;
}

.section__header {
  margin-bottom: 60px;
  margin-top: -8px;
}

.section__header .sub-title-two {
  color: var(--white);
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
}

.section__header .sub-title-two::before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 2px;
  background-color: var(--primary-color);
  border-radius: 20px;
}

.section__header .title {
  margin-top: 30px;

  font-weight: 700;
  color: var(--white);
}

.section__header p {
  color: var(--quinary-color);
  margin-top: 24px;
}

.section__header--secondary {
  margin-bottom: 60px;
}

.section__header--secondary .sub-title-two::before {
  left: 0px;
  transform: translateX(0px);
}

.section__content .sub-title {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  padding: 12px 20px;
  line-height: 1;
  background-color: rgba(101, 255, 75, 0.1254901961);
  color: var(--white);
  border-radius: 10px;
  text-transform: capitalize;
  font-weight: 600;
}

.section__content .sub-title-two {
  color: var(--white);
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
  margin-top: -8px;
}

.section__content .sub-title-two::before {
  content: "";
  position: absolute;
  bottom: -12px;
  width: 40px;
  height: 2px;
  background-color: var(--primary-color);
  border-radius: 20px;
}

.section__content .title {
  margin-top: 30px;

  font-weight: 700;
  color: var(--white);
}

.section__content p {
  color: var(--quinary-color);
  margin-top: 24px;
}

.cta-group {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}

.social li {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.social a {
  color: var(--tertiary-color);
  background-color: #f0efff;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.social a:hover {
  color: var(--black);
  background-color: var(--primary-color);
}

@keyframes rotateInfinite {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.slide-btn-group {
  display: flex;
  align-items: center;
  gap: 24px;
}

.slide-btn {
  width: 74px;
  min-width: 74px;
  height: 74px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #414141;
  transition: var(--transition);
  background-color: transparent;
  color: #414141;
  font-size: 40px;
  z-index: 2;
}

.slide-btn:hover {
  color: var(--white);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.slider-pagination-group .slick-dots {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: inherit;
}

.slider-pagination-group .slick-dots button {
  width: 14px;
  height: 14px;
  background-color: #595765;
  position: relative;
  transition: var(--transition);
  border-radius: 50%;
  text-indent: -99999px !important;
}

.slider-pagination-group .slick-dots .slick-active button {
  background-color: var(--primary-color);
  border-radius: 15px;
  width: 50px;
}

.mt-12 {
  margin-top: -8px !important;
}

.sidebar-cart {
  background: var(--black);
  color: var(--white);
  padding: 20px 20px 0 20px;
  position: fixed;
  display: block;
  width: 420px;
  height: 100vh;
  z-index: 99999;
  top: 0;
  right: 0px;
  transform: translateX(100%);
  box-shadow: -10px 0 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
  border-left: 1px solid #414141;
}

.sidebar-cart .close-cart {
  height: 16px;
  width: 16px;
  margin: 0 0 15px 0;
  text-decoration: none;
  position: absolute;
  font-size: 24px;
  top: 29px;
  left: 20px;
  display: flex;
  background-color: transparent;
  border: 0px;
  outline: 0px;
  color: white;
  z-index: 99;
}

.sidebar-cart h2 {
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 1;
  margin: 5px 0 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-cart h2 .count-item {
  color: var(--tertiary-color);
  background: var(--secondary-color);
  padding: 8px;
  margin-left: 6px;
  position: relative;
  top: -1px;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  font-size: 12px;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-cart .cart-items {
  margin-top: 30px;
}

.sidebar-cart .cart-item-single {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px 0px;
  border-bottom: 1px solid #414141;
  padding-right: 24px;
  position: relative;
}

.sidebar-cart .cart-item-single .delete-item {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  color: var(--primary-color);
  background-color: transparent;
  font-size: 20px;
}

.sidebar-cart .cart-item-single .cart-item-thumb a {
  width: 80px;
  height: 100px;
}

.sidebar-cart .cart-item-single .cart-item-thumb a img {
  width: 100%;
  height: 100%;
}

.sidebar-cart .cart-item-single h6 {
  max-width: 140px;
}

.sidebar-cart .cart-item-single h6 a {
  font-weight: 600;
  color: var(--white);
  font-size: 16px;
}

.sidebar-cart .cart-item-single h6 a:hover {
  color: var(--primary-color);
}

.sidebar-cart .cart-item-single .price {
  font-weight: 600;
  font-size: 14px;
  color: var(--quinary-color);
}

.sidebar-cart .cart-item-single .measure {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 10px;
}

.sidebar-cart .cart-item-single .measure button {
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  background-color: var(--white);
  color: var(--black);
  font-size: 12px;
}

.sidebar-cart .cart-item-single .item-quantity {
  min-width: 16px;
  color: var(--white);
  font-weight: 700;
  text-align: center;
}

.sidebar-cart .sidebar-cart__inner {
  height: calc(100% - 140px);
  overflow-y: auto;
}

.sidebar-cart .totals {
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  height: 56px;
  background: var(--white);
  border-bottom: 1px solid var(--white);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.sidebar-cart .totals .subtotal,
.sidebar-cart .totals .shipping,
.sidebar-cart .totals .tax {
  padding: 15px;
  text-align: center;
  color: var(--white);
  border-bottom: 1px solid var(--white);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.sidebar-cart .totals .subtotal .amount,
.sidebar-cart .totals .subtotal .total-price,
.sidebar-cart .totals .shipping .amount,
.sidebar-cart .totals .shipping .total-price,
.sidebar-cart .totals .tax .amount,
.sidebar-cart .totals .tax .total-price {
  color: var(--tertiary-color);
  margin-left: 10px;
  font-weight: 700;
}

.sidebar-cart .totals .subtotal .total-price,
.sidebar-cart .totals .shipping .total-price,
.sidebar-cart .totals .tax .total-price {
  margin: 0px;
}

.sidebar-cart .totals .subtotal .label,
.sidebar-cart .totals .shipping .label,
.sidebar-cart .totals .tax .label {
  color: var(--tertiary-color);
  font-weight: 700;
}

.sidebar-cart .action-buttons {
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80px;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  border-top: 1px solid rgba(0, 0, 0, 0.3137254902);
}

.sidebar-cart .action-buttons .view-cart-button,
.sidebar-cart .action-buttons .checkout-button {
  display: inline-block;
  padding: 10px;
  margin: 20px 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  transition: all 0.5s linear;
}

.sidebar-cart .action-buttons .view-cart-button {
  background: var(--black);
  border-color: var(--secondary-color);
  margin-right: 5px;
  color: var(--secondary-color);
  width: 80px;
}

.sidebar-cart .action-buttons .checkout-button {
  border-color: var(--secondary-color);
  background: var(--black);
  margin-left: 5px;
  color: var(--white);
  width: 200px;
}

.sidebar-cart .empty-cart {
  margin-top: 60px;
}

.sidebar-cart .empty-cart h5,
.sidebar-cart .empty-cart a {
  color: white;
}

.sidebar-cart .empty-cart a {
  color: var(--quinary-color);
  margin-top: 16px;
}

.sidebar-cart .empty-cart a:hover {
  color: var(--primary-color);
}

.sidebar-cart-active {
  transform: translateX(0px);
}

.cart-backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.57);
  z-index: 9999;
  transform: translateX(-100%);
  transition: all 0.6s ease-in-out;
}

.cart-backdrop-active {
  transform: translateX(0px);
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;
}

.search-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  margin-top: -540px;
  transform: translateY(-100%);
  background-color: rgba(0, 0, 0, 0.9);
  transition: all 1500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.search-popup form {
  position: absolute;
  max-width: 700px;
  top: 50%;
  left: 15px;
  right: 15px;
  margin: -35px auto 0;
  transform: scaleX(0);
  transform-origin: center;
  background-color: #111111;
  transition: all 300ms ease;
}

.search-popup .search-popup__group {
  position: relative;
  margin: 0px;
  overflow: hidden;
}

.search-popup .search-popup__group input {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 50px;
  color: var(--black);
  height: 70px;
  width: 100%;
  padding: 10px 30px;
  background-color: var(--white);
  transition: all 500ms ease;
  font-weight: 400;
  text-transform: capitalize;
  padding-right: 80px;
}

.search-popup .search-popup__group input::placeholder {
  opacity: 0.5;
}

.search-popup button {
  position: absolute;
  right: 30px;
  top: 0px;
  height: 70px;
  line-height: 70px;
  background: transparent;
  text-align: center;
  font-size: 24px;
  color: var(--black);
  padding: 0;
  cursor: pointer;
  transition: all 500ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-popup .close-search {
  position: absolute;
  left: 0;
  right: 0;
  top: 75%;
  margin: 0 auto;
  margin-top: -200px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--primary-color);
  width: 70px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid var(--white);
  transition: all 500ms ease;
  opacity: 0;
  visibility: hidden;
}

.search-popup .close-search span {
  position: relative;
  display: block;
  height: 70px;
  width: 70px;
  font-size: 20px;
  line-height: 70px;
  color: var(--white);
}

.sidenav-bar-visible .search-popup {
  width: 80%;
}

.search-active .search-popup {
  transform: translateY(0%);
  margin-top: 0;
}

.search-active .search-popup form {
  transform: scaleX(1);
  transition-delay: 1200ms;
}

.search-active .search-popup .close-search {
  visibility: visible;
  opacity: 1;
  top: 50%;
  transition-delay: 1500ms;
}

.offcanvas-info {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  min-height: 100vh;
  width: 100%;
  max-width: 520px;
  /* background-color: var(--primary-color); */
  /* background-image: linear-gradient(132deg, #65ff4b 0%, #46e695 100%); */
  background-color: #262626;
  z-index: 99999;
  transform: translateX(100%);
  box-shadow: -10px 0 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
}

.offcanvas-info .offcanvas-info__inner {
  display: flex;
  flex-direction: column;
  padding: 80px 40px;
  gap: 60px;
  height: 100%;
  overflow-y: auto;
}

.offcanvas-info .offcanvas-info__inner::-webkit-scrollbar {
  width: 0px;
}

.offcanvas-info .offcanvas-info__intro h4 {
  max-width: 320px;
  color: var(--quaternary-color);
  font-weight: 600;
  margin-top: 40px;
}

.offcanvas-info .offcanvas-info__content h5 {
  color: var(--quaternary-color);
  font-weight: 600;
  text-transform: capitalize;
}

.offcanvas-info .offcanvas-info__content ul {
  max-width: 350px;
  margin-top: 24px;
}

.offcanvas-info .offcanvas-info__content li {
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}

.offcanvas-info .offcanvas-info__content li::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--quaternary-color);
}

.offcanvas-info .offcanvas-info__content li:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.offcanvas-info .offcanvas-info__content li a {
  color: var(--quaternary-color);
  font-weight: 400;
  line-height: 30px;
}

.offcanvas-info .offcanvas-info__content li a:hover {
  color: var(--black);
}

.offcanvas-info .offcanvas-info__form h5 {
  color: var(--quaternary-color);
  font-weight: 600;
  text-transform: capitalize;
}

.offcanvas-info .offcanvas-info__form .subscribe-form {
  margin-top: 30px;
}

.offcanvas-info .offcanvas-info__footer {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.offcanvas-info .offcanvas-info__footer p {
  color: rgba(25, 27, 26, 0.5019607843);
}

.offcanvas-info .offcanvas-info__footer a {
  width: auto;
  height: auto;
  min-width: auto;
  background-color: transparent;
  font-size: 24px;
}

.offcanvas-info .offcanvas-info__footer a:hover {
  color: var(--black);
}

.offcanvas-info .close-offcanvas-info {
  position: absolute;
  top: -24px;
  right: -24px;
  width: 124px;
  min-width: 124px;
  height: 124px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--quaternary-color);
  color: var(--primary-color);
  font-size: 30px;
  justify-content: flex-start;
  padding-left: 40px;
  padding-top: 12px;
  z-index: 9;
}

.offcanvas-info-active {
  transform: translateX(0px);
}

.offcanvas-info-backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.57);
  z-index: 9999;
  transform: translateX(-100%);
  transition: all 0.6s ease-in-out;
}

.offcanvas-info-backdrop-active {
  transform: translateX(0px);
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;
}

@keyframes globalImageAnimation {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.section__header .sub-title-two,
.section__content .sub-title-two {
  --width: 0px;
}

.section__header .sub-title-two::before,
.section__content .sub-title-two::before {
  width: var(--width);
}

.reveal-img {
  position: relative;
  display: inline-block;
}

.reveal-img img {
  opacity: 0;
}

.reveal-img::after {
  content: "";
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #40cc28;
  opacity: 0.3;
  position: absolute;
}

.reveal-img-active img {
  animation: imgAppear 1s linear forwards;
  animation-delay: 0.8s;
}

.reveal-img-active::after {
  animation: revealImg 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

@keyframes imgAppear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes revealImg {
  0% {
    left: 0%;
    width: 0%;
  }

  50% {
    left: 0%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 0%;
  }
}

#particles-js {
  position: fixed;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* background-image: url(""); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -1;
}

.home-five,
.home-six {
  background-color: white;
}

.home-five .title,
.home-six .title {
  color: #191b1a;
}

.home-five p,
.home-six p {
  color: #4c4a59;
}

.l-t {
  font-size: 140px;
  line-height: 150px;
  color: #c0bfc5;
  font-weight: 800;
  text-align: center;
  margin-top: -25px;
}

.horizon {
  height: 1px;
  background-color: rgba(164, 163, 171, 0.3137254902);
  width: 100%;
}

.poster-img {
  margin-bottom: 40px;
}

.poster-img img {
  width: 100%;
  min-height: 260px;
  border-radius: 20px;
}

.text-group {
  margin-bottom: 40px;
}

.text-group:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.text-group p {
  margin-bottom: 24px;
  color: #b1b0b6;
}

.text-group p:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.text-group h4 {
  color: white;
  margin-bottom: 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.text-group h2 {
  margin-bottom: 24px;
}

.text-group ul,
.text-group ol {
  margin-top: 24px;
  list-style-position: outside;
  padding-left: 20px;
}

.text-group ul li,
.text-group ol li {
  margin-bottom: 16px;
  color: #b1b0b6;
}

.text-group ul li:nth-last-of-type(1),
.text-group ol li:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.text-group ol {
  list-style-type: number;
}

.img-group {
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 60px 0px;
}

.s-details-single {
  border-radius: 20px;
  padding: 120px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.s-details-contact {
  padding: 40px;
  border-radius: 20px;
  background-color: #120f23;
  margin-top: 24px;
}

.s-details-contact li {
  margin-bottom: 20px;
}

.s-details-contact li:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.s-details-contact li a {
  display: flex;
  align-items: center;
  gap: 16px;
  color: white;
  font-weight: 400;
}

.s-details-contact li a:hover {
  color: var(--primary-color);
}

.s-details-contact li a span {
  font-size: 24px;
  color: var(--primary-color);
  min-width: 24px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
}

.pagination button,
.pagination a {
  width: 44px;
  min-width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-radius: 10px;
  background-color: #120f23;
  color: white;
  transition: var(--transition);
}

.pagination button:hover,
.pagination a:hover {
  background-color: var(--primary-color);
  color: #191b1a;
  border: 0px;
}

.pagination .active {
  background-color: var(--primary-color);
  color: #191b1a;
}

.pagination button:hover {
  background-color: var(--primary-color);
  color: #191b1a;
}

/* ====
   --------- (2.03) global styles end ---------
   ==== */
/* ====
 --------- (3.01) buttons styles start ---------
 ==== */
.btn {
  padding: 16px 40px;
  font-weight: 400;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  color: var(--primary-color);
  position: relative;
  overflow: hidden;
  text-transform: capitalize;
}

.btn:hover {
  border: 1px solid var(--primary-color);
  color: var(--white);
}

.btn:focus {
  box-shadow: none;
  outline: 0px;
}

.btn--primary {
  z-index: 1;
  color: var(--quaternary-color);
  background-image: linear-gradient(140deg, #f9c613 0%, #f9c613 100%);
  border: 0px;
}

.btn--primary::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 0px;
  height: 100%;
  /*background-image: linear-gradient(-140deg, #65ff4b 0%, #46e695 100%);*/
  transition: all 0.6s ease;
  z-index: -1;
}

.btn--primary:hover {
  color: var(--black);
  border: 0px;
}

.btn--primary:hover::before {
  width: 100%;
  left: 0px;
}

.btn--secondary {
  z-index: 1;
  color: var(--white);
  border: 0px;
  background-color: #302e3f;
  text-transform: capitalize;
}

.btn--secondary::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 0px;
  height: 100%;
  background-image: linear-gradient(140deg, #f9c613 0%, #f9c613 100%);
  transition: all 0.6s ease;
  z-index: -1;
}

.btn--secondary:hover {
  color: var(--black);
  border: 0px;
}

.btn--secondary:hover::before {
  width: 100%;
  left: 0px;
}

.btn--tertiary {
  z-index: 1;
  color: var(--primary-color);
  border: 1px solid black !important;
  background-color: #181818;
  gap: 20px;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
}

.btn--tertiary::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 0px;
  height: 100%;
  background-color: var(--primary-color);
  transition: all 0.6s ease;
  z-index: -1;
}

.btn--tertiary:hover {
  border: 1px solid var(--primary-color);
  color: var(--black);
}

.btn--tertiary:hover::before {
  width: 100%;
  left: 0px;
}

.btn--quaternary {
  z-index: 1;
  color: var(--primary-color);
  border: 1px solid var(--primary-color) !important;
  background-color: #181818;
  gap: 20px;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 600;
}

.btn--quaternary::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 0px;
  height: 100%;
  background-image: linear-gradient(140deg, #65ff4b 0%, #46e695 100%);
  transition: all 0.6s ease;
  z-index: -1;
}

.btn--quaternary:hover {
  border: 1px solid var(--primary-color);
  color: var(--black);
}

.btn--quaternary:hover::before {
  width: 100%;
  left: 0px;
}

.progress-wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  background-color: #1f1f1f;
  box-shadow: inset 0 0 0 8px #1f1f1f;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  transition: all 200ms linear;
  z-index: 99;
  overflow: hidden;
}

.progress-wrap span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 34px;
  height: 34px;
  background-color: var(--white);
  border-radius: 50%;
  overflow: hidden;
}

.progress-wrap span::after {
  content: "\f148";
  font-family: "bootstrap-icons";
  font-weight: 900;
  position: absolute;
  text-align: center;
  line-height: 34px;
  font-size: 16px;
  border-radius: 50%;
  color: var(--black);
  left: 50%;
  top: 50%;
  transform: translate(-50%, 200%);
  height: 34px;
  width: 34px;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: all 200ms linear;
}

.progress-wrap span::before {
  position: absolute;
  content: "\f148";
  font-family: "bootstrap-icons";
  font-weight: 900;
  text-align: center;
  line-height: 34px;
  font-size: 16px;
  border-radius: 50%;
  color: var(--black);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 34px;
  width: 34px;
  cursor: pointer;
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}

.progress-wrap:hover span::before {
  transform: translate(-50%, -200%);
}

.progress-wrap:hover span::after {
  transform: translate(-50%, -50%);
}

.progress-wrap path {
  fill: none;
}

.progress-wrap .progress-circle path {
  stroke: var(--primary-color);
  stroke-width: 4;
  box-sizing: content-box;
  transition: all 200ms linear;
}

.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.video-modal-btn a {
  width: 84px;
  min-width: 84px;
  height: 84px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  font-size: 20px;
  color: var(--white);
}

@keyframes wave {
  0% {
    transform: translate(-50%, -50%) scale(0.6);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }

  100% {
    transform: translate(-50%, -50%) scale(1.7);
    opacity: 0;
  }
}

/* ====
 --------- (3.01) buttons styles end ---------
 ==== */
/* ====
 --------- (3.02) forms styles start ---------
 ==== */
.subscribe-form {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 360px;
}

.subscribe-form input {
  flex-grow: 1;
  padding: 22px 20px;
  background-color: var(--quaternary-color);
  border-radius: 40px;
  color: var(--white);
  width: calc(100% - 70px);
}

.subscribe-form button {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--quaternary-color);
  color: var(--primary-color);
  font-size: 24px;
}

/* ====
 --------- (3.02) forms styles end ---------
 ==== */
/* ====
 --------- (3.03) preloader styles start ---------
 ==== */
.ctn-preloader {
  align-items: center;
  cursor: none;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99999;
}

.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

.ctn-preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.3137254902);
  border-top-color: var(--primary-color);
  height: 9em;
  margin: 0 auto 3.5em auto;
  width: 9em;
}

.ctn-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}

.ctn-preloader .animation-preloader .txt-loading span {
  font-size: 30px;
  letter-spacing: 2px;
  font-weight: 400;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: var(--primary-color);
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: rotateY(-90deg);
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  color: rgba(255, 255, 255, 0.3137254902);
  position: relative;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}

.ctn-preloader .loader-section {
  background-color: #030015;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.ctn-preloader .loader-section.section-left {
  left: 0;
}

.ctn-preloader .loader-section.section-right {
  right: 0;
}

.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

.loaded .loader-section.section-left {
  background-color: var(--primary-color) !important;
  transform: translateX(-101%);
  transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1);
}

.loaded .loader-section.section-right {
  transform: translateX(101%);
  transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1);
}

@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes letters-loading {

  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }

  .ctn-preloader .animation-preloader .txt-loading span {
    font-size: 24px;
  }
}

@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }

  .ctn-preloader .animation-preloader .txt-loading span {
    font-size: 20px;
  }
}

/* ====
 --------- (3.03) preloader styles end ---------
 ==== */
/* ====
 --------- (4.01) header styles start ---------
 ==== */
.primary-navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 999;
  border-bottom: 1px solid #3d3b4b;
}

.primary-navbar .navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
  flex-wrap: wrap;
}

.primary-navbar .navbar__menu {
  flex-grow: 1;
}

.primary-navbar .navbar__list {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3d3b4b;
  border-top: 0px;
  border-bottom: 0px;
}

.primary-navbar .navbar__item button,
.primary-navbar .navbar__item a {
  padding: 41px 20px;
  font-weight: 500;
  width: 100%;
  color: var(--white);
}

.primary-navbar .navbar__item button:hover,
.primary-navbar .navbar__item a:hover {
  color: var(--primary-color);
}

.primary-navbar .navbar__item--has-children {
  position: relative;
}

.primary-navbar .navbar__item--has-children:hover>.navbar__dropdown-label {
  color: var(--primary-color);
}

.primary-navbar .navbar__item--has-children:hover>.navbar__dropdown-label::after {
  transform: rotate(180deg);
}

.primary-navbar .navbar__item--has-children:hover>.navbar__dropdown-label-sub::after {
  transform: rotate(-90deg);
}

.primary-navbar .navbar__item--has-children:hover>.navbar__sub-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  pointer-events: all;
}

.primary-navbar .navbar__dropdown-label {
  position: relative;
  justify-content: space-between;
}

.primary-navbar .navbar__dropdown-label::after {
  font-family: "bootstrap-icons";
  font-weight: 900;
  content: "\f282";
  border: none;
  transition: transform 0.3s ease-in-out;
  font-size: inherit;
  transform: rotate(0deg);
}

.primary-navbar .navbar__sub-menu {
  position: absolute;
  top: 100%;
  left: 0px;
  min-width: 220px;
  max-width: 260px;
  background-color: var(--white);
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  pointer-events: none;
  transition: var(--transition);
  box-shadow: var(--shadow);
  padding: 20px 0px;
  border-radius: 5px;
  z-index: 9;
}

.primary-navbar .navbar__sub-menu::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 25px;
  height: 15px;
  width: 15px;
  transform: rotate(45deg);
  background-color: inherit;
  transition: var(--transition);
  z-index: -1;
}

.primary-navbar .navbar__sub-menu li:nth-last-of-type(1) a {
  border-bottom: 0px;
}

.primary-navbar .navbar__sub-menu a,
.primary-navbar .navbar__sub-menu button {
  width: 100%;
  display: flex;
  padding: 16px 40px;
  color: var(--black);
  position: relative;
  font-size: 14px;
  border-bottom: 1px solid rgba(206, 206, 206, 0.4784313725);
}

.primary-navbar .navbar__sub-menu a::before,
.primary-navbar .navbar__sub-menu button::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  height: 1px;
  width: 0px;
  transition: var(--transition);
  background-color: #1f1c2f;
}

.primary-navbar .navbar__sub-menu a:hover,
.primary-navbar .navbar__sub-menu button:hover {
  color: #1f1c2f;
}

.primary-navbar .navbar__sub-menu a:hover::before,
.primary-navbar .navbar__sub-menu button:hover::before {
  width: 10px;
}

.primary-navbar .navbar__sub-menu .navbar__item--has-children:hover>.navbar__dropdown-label-sub {
  color: #1f1c2f;
}

.primary-navbar .navbar__sub-menu .navbar__dropdown-label-sub::before {
  display: none;
}

.primary-navbar .navbar__sub-menu__nested {
  top: 0%;
  left: 100%;
  min-width: 220px;
}

.primary-navbar .navbar__sub-menu__nested::before {
  left: -5px;
  top: 17px;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);
}

.primary-navbar .navbar__sub-menu-n {
  display: none;
}

.primary-navbar .navbar__options {
  display: flex;
  align-items: center;
  gap: 40px;
}

.primary-navbar .navbar__mobile-options {
  display: flex;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}

.primary-navbar .navbar__mobile-options button,
.primary-navbar .navbar__mobile-options .prevent-cart {
  font-size: 24px;
  color: var(--quinary-color);
}

.primary-navbar .navbar__mobile-options button:hover,
.primary-navbar .navbar__mobile-options .prevent-cart:hover {
  color: var(--primary-color);
}

.primary-navbar .open-mobile-menu {
  font-size: 30px;
  color: var(--quinary-color);
}

.primary-navbar .open-mobile-menu:hover {
  color: var(--primary-color);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes navLinkFadeReverse {
  from {
    opacity: 0;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes stickyNavbar {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0px);
  }
}

.navbar-active {
  background-color: var(--black);
  animation: stickyNavbar 0.9s ease-in-out;
  box-shadow: var(--shadow);
}

.home-five .primary-navbar,
.home-six .primary-navbar {
  border-bottom: 1px solid #ebebec;
}

.home-five .primary-navbar .navbar__list,
.home-six .primary-navbar .navbar__list {
  border: 1px solid #ebebec;
  border-top: 0px;
  border-bottom: 0px;
}

.home-five .primary-navbar .navbar__item button,
.home-five .primary-navbar .navbar__item a,
.home-six .primary-navbar .navbar__item button,
.home-six .primary-navbar .navbar__item a {
  color: #191b1a;
}

.home-five .primary-navbar .navbar__item button:hover,
.home-five .primary-navbar .navbar__item a:hover,
.home-six .primary-navbar .navbar__item button:hover,
.home-six .primary-navbar .navbar__item a:hover {
  color: #000000 !important;
}

.home-five .primary-navbar .navbar__item:hover a,
.home-five .primary-navbar .navbar__item:hover button,
.home-six .primary-navbar .navbar__item:hover a,
.home-six .primary-navbar .navbar__item:hover button {
  color: #000000;
}

.home-five .primary-navbar .navbar__mobile-options button,
.home-six .primary-navbar .navbar__mobile-options button {
  color: #191b1a;
}

.home-five .primary-navbar .navbar__mobile-options button:hover,
.home-six .primary-navbar .navbar__mobile-options button:hover {
  color: #000000 !important;
}

.home-five .primary-navbar .open-mobile-menu,
.home-six .primary-navbar .open-mobile-menu {
  color: #191b1a;
}

.home-five .primary-navbar .open-mobile-menu:hover,
.home-six .primary-navbar .open-mobile-menu:hover {
  color: #000000 !important;
}

.home-five .navbar-active,
.home-six .navbar-active {
  background-color: white;
}

.home-five .mobile-menu,
.home-six .mobile-menu {
  background-color: white;
}

.home-five .mobile-menu .mobile-menu__wrapper,
.home-six .mobile-menu .mobile-menu__wrapper {
  background-color: white;
}

.home-five .mobile-menu .navbar__list>li button,
.home-five .mobile-menu .navbar__list>li a,
.home-six .mobile-menu .navbar__list>li button,
.home-six .mobile-menu .navbar__list>li a {
  border-bottom: 1px solid #ebebec;
}

.home-five .mobile-menu .navbar__list>li:nth-of-type(1),
.home-six .mobile-menu .navbar__list>li:nth-of-type(1) {
  border-top: 1px solid #ebebec;
}

.home-five .mobile-menu .navbar__item--has-children .navbar__dropdown-label::after,
.home-six .mobile-menu .navbar__item--has-children .navbar__dropdown-label::after {
  border-left: 1px solid #ebebec;
}

.home-five .mobile-menu .navbar__item-active,
.home-six .mobile-menu .navbar__item-active {
  color: #000000 !important;
}

.home-five .mobile-menu .navbar__sub-menu li:nth-last-of-type(1) a,
.home-five .mobile-menu .navbar__sub-menu li:nth-last-of-type(1) button,
.home-six .mobile-menu .navbar__sub-menu li:nth-last-of-type(1) a,
.home-six .mobile-menu .navbar__sub-menu li:nth-last-of-type(1) button {
  border-bottom: 1px solid #ebebec;
}

.home-five .mobile-menu .navbar__sub-menu,
.home-six .mobile-menu .navbar__sub-menu {
  box-shadow: none !important;
}

.home-five .mobile-menu .close-mobile-menu,
.home-six .mobile-menu .close-mobile-menu {
  color: #000000 !important;
}

.home-five .mobile-menu .close-mobile-menu:hover,
.home-six .mobile-menu .close-mobile-menu:hover {
  color: #000000 !important;
}

.home-five .mobile-menu__backdrop,
.home-six .mobile-menu__backdrop {
  background-color: rgba(0, 0, 0, 0.67);
}

.home-seven .primary-navbar {
  border-color: rgba(255, 255, 255, 0.05);
}

.home-seven .primary-navbar .navbar__list {
  border-color: rgba(255, 255, 255, 0.05);
}

/* ====
 --------- (4.01) header styles end ---------
 ==== */
/* ====
 --------- (4.02) banner styles start ---------
 ==== */
.banner {
  padding: 220px 0px 0px;
  background-color: var(--theme-bg);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.banner::before {
  position: absolute;
  top: 0px;
  left: -180px;
  content: "";
  width: 600px;
  height: 600px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 255, 223, 0.24);
  filter: blur(227px);
  z-index: -1;
}

.banner::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -100px;
  content: "";
  width: 430px;
  height: 430px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 169, 255, 0.44);
  filter: blur(272px);
  z-index: -1;
}

.banner .banner__content {
  margin-bottom: 120px;
}

.banner h1 {
  color: var(--white);
  margin: 0px;
}

.banner h1 span {
  color: var(--primary-color);
}

.banner .banner-anime {
  position: relative;
  z-index: 1;
  color: var(--white);
  margin-left: 10px;
}

.banner .banner-anime::before {
  content: "";
  position: absolute;
  inset: 0px;
  top: 50%;
  background-color: var(--primary-color);
  z-index: -1;
  width: 110%;
  left: 50%;
  height: 90%;
  z-index: -1;
  opacity: 0.5;
  transform: translateY(-50%) translateX(-50%) rotate(-5deg);
}

.banner .banner__large-slider,
.banner .banner__small-slider {
  transform: rotate(-4deg);
}

.banner .banner__large-slider {
  position: relative;
  z-index: 1;
}

.banner .banner__large-slider::before {
  content: "";
  position: absolute;
  top: -100px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 200px;
  background-image: url("../images/banner/line.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.banner .banner__small-slider {
  margin-bottom: -140px;
  margin-top: 9px;
}

.banner .banner__large-slider__single img {
  border-radius: 20px;
}

.banner .banner__small-slider__single img {
  border-radius: 20px;
}

.banner .banner-thumb-one {
  position: absolute;
  top: 140px;
  left: -3%;
  z-index: -1;
}

.banner .banner-thumb-one::before {
  content: "";
  position: absolute;
  top: 60%;
  left: 60%;
  width: 100%;
  max-width: 10vw;
  height: 130px;
  background-image: url("../images/footer/shape-two.png");
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 24px;
  z-index: 1;
  animation: globalImageAnimation 6s ease-in-out infinite alternate-reverse;
}

.banner .banner-thumb-one img {
  max-width: 10vw;
  min-width: 60px;
  transform: rotate(-24deg);
}

.banner .scroll-position-btn {
  position: absolute;
  top: 30%;
  transform: translateY(-30%);
  right: 6%;
  width: 230px;
  min-width: 230px;
  height: 230px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: var(--primary-color);
  background-color: rgba(101, 255, 75, 0.21);
  z-index: 1;
}

.banner .scroll-position-btn img {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  transform: translate(-50%, -50%);
  animation: rotateInfinite 24s linear infinite;
  animation-play-state: running;
  display: inline-block;
  z-index: -1;
}

.banner .scroll-position-btn:hover img {
  animation-play-state: paused;
}

.banner-two {
  padding: 236px 0px;
  background-size: cover;
  background-position: left bottom;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.banner-two::before {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 10%;
  width: 15vw;
  max-width: 140px;
  min-width: 40px;
  height: 0%;
  background-color: var(--primary-color);
  opacity: 0.7;
  z-index: 2;
  animation: ano 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  animation-delay: 1s;
}

.banner-two::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 3%;
  width: 15vw;
  max-width: 140px;
  min-width: 40px;
  height: 0%;
  opacity: 0.6999999881;
  background: rgba(255, 255, 255, 0.44);
  z-index: -1;
  animation: ant 1.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  animation-delay: 1.6s;
}

@keyframes ano {
  0% {
    height: 0px;
  }

  100% {
    height: 34%;
  }
}

@keyframes ant {
  0% {
    height: 0px;
  }

  100% {
    height: 60%;
  }
}

.banner-two .banner-two__content h1,
.banner-two .banner-two__content p {
  color: var(--white);
}

.banner-two .banner-two__content h1 {
  font-weight: 700;
  margin-bottom: 30px;
}

.banner-two .banner-two__content h1 span {
  background: linear-gradient(107deg, #f9c613 0%, #f9c613 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-two .banner-two__thumb {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.banner-two .banner-two__thumb img {
  max-width: 50vw;
}

.banner-two .scroll-position-btn {
  position: absolute;
  top: 140px;
  right: 6%;
  width: 130px;
  min-width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: var(--primary-color);
  border: 10px solid rgba(101, 255, 75, 0.21);
  z-index: 1;
  top: 160px;
  margin-right: 5px;
}

.banner-two .scroll-position-btn img {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  animation: rotateInfinite 24s linear infinite;
  animation-play-state: running;
  display: inline-block;
  z-index: -1;
}

.banner-two .scroll-position-btn:hover img {
  animation-play-state: paused;
}

.banner-three {
  padding-top: 220px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.banner-three h1 {
  color: white;
}

.banner-three h1 span {
  color: var(--primary-color);
}

.banner-three .banner-three__thumb {
  margin-top: 40px;
}

.banner-three .banner-three__thumb img {
  max-width: 100%;
  height: auto;
}

.banner-three .banner-t-s-thumb {
  position: absolute;
  top: 25%;
  left: 8%;
  z-index: -1;
}

.banner-three .banner-t-s-thumb::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -10px;
  right: -10px;
  border-radius: 100px;
  border: 1px solid #65ff4b;
  z-index: -2;
}

.banner-three .banner-t-s-thumb img {
  border-radius: 100px;
}

.banner-three .scroll-position-btn {
  position: absolute;
  top: 20%;
  transform: translateY(-20%);
  right: 6%;
  width: 180px;
  min-width: 180px;
  height: 180px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: var(--primary-color);
  background-color: rgba(101, 255, 75, 0.21);
  z-index: -1;
}

.banner-three .scroll-position-btn img {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  transform: translate(-50%, -50%);
  animation: rotateInfinite 24s linear infinite;
  animation-play-state: running;
  display: inline-block;
  z-index: -1;
}

.banner-three .scroll-position-btn:hover img {
  animation-play-state: paused;
}

.banner-four {
  padding: 220px 0px 160px;
}

.banner-five {
  padding-top: 220px;
  background-color: rgba(230, 230, 230, 0.2509803922);
  background-repeat: no-repeat;
  background-size: auto, auto;
  background-position: left top, right top;
  position: relative;
  overflow-x: clip;
  z-index: 1;
}

.banner-five .title-animation {
  text-transform: uppercase !important;
  position: relative;
}

.banner-five h1,
.banner-five .l-t {
  font-size: 140px;
  line-height: 150px;
  color: #c0bfc5;
  font-weight: 800;
  text-align: center;
  margin-top: -25px;
}

.banner-five .banner-five__thumb {
  margin-bottom: -20px;
}

.banner-five .banner-five__thumb img {
  max-width: 420px;
}

.banner-five .b-five-wrapper {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -1;
  opacity: 0.3;
}

.banner-five .b-five-wrapper .l-t {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px #dedee1;
  font-family: var(--inter);
}

.banner-five .b-f-s-thumb {
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
}

.banner-five .b-f-s-thumb img {
  max-width: 310px;
}

.banner-five .scroll-position-btn {
  position: absolute;
  top: 70%;
  transform: translateY(-70%);
  right: 12%;
  width: 180px;
  min-width: 180px;
  height: 180px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #191919;
}

.banner-five .scroll-position-btn img {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  transform: translate(-50%, -50%);
  animation: rotateInfinite 24s linear infinite;
  animation-play-state: running;
  display: inline-block;
  z-index: -1;
}

.banner-five .scroll-position-btn:hover img {
  animation-play-state: paused;
}

.banner-six {
  padding: 220px 0px 160px;
}

.banner-six .gaper {
  row-gap: 40px;
}

.banner-six .banner-six__content {
  margin-bottom: 80px;
}

.banner-six .banner-six__content .title {
  margin-top: -10px;
}

.banner-six .banner-six__single .thumb a {
  width: 100%;
  overflow: hidden;
}

.banner-six .banner-six__single .thumb a img {
  width: 100%;
  min-height: 260px;
  transition: var(--transition);
}

.banner-six .banner-six__single .content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  row-gap: 12px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.banner-six .banner-six__single h4 {
  max-width: 420px;
}

.banner-six .banner-six__single h4 a {
  color: #191b1a;
  font-weight: 700;
}

.banner-six .banner-six__single:hover .thumb img {
  transform: scale(1.1);
}

.banner-seven {
  padding: 260px 0px 100px;
  background: url("../images/banner/banner-s-bg.png"), radial-gradient(172.95% 76.92% at 46.67% 50%, #735aff 0%, #0b0433 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.banner-seven h1 {
  letter-spacing: 1px;
}

.banner-seven h1,
.banner-seven p {
  color: white;
  text-transform: capitalize !important;
}

.banner-seven .banner-seven__group {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 30px;
}

.banner-seven .banner-seven__group .thumb {
  max-width: 300px;
  min-width: 300px;
  position: relative;
}

.banner-seven .banner-seven__group .thumb a {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  width: 90px;
  min-width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  font-size: 40px;
  color: #191b1a;
}

.banner-seven .banner-seven__group img {
  width: 100%;
  border-radius: 100px;
  min-height: 110px;
}

.banner-seven .banner-seven__group .content {
  flex-grow: 1;
}

.banner-seven .scroll-position-btn {
  width: 150px;
  min-width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-top: 80px;
  font-size: 50px;
  color: var(--primary-color);
  background-color: rgba(101, 255, 75, 0.21);
  z-index: 1;
  position: relative;
}

.banner-seven .scroll-position-btn i {
  transform: rotate(-30deg);
}

.banner-seven .scroll-position-btn img {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  transform: translate(-50%, -50%);
  animation: rotateInfinite 24s linear infinite;
  animation-play-state: running;
  display: inline-block;
  z-index: -1;
}

.banner-seven .scroll-position-btn:hover img {
  animation-play-state: paused;
}

.banner-seven .banner-seven__thumb {
  position: absolute;
  right: 100px;
  bottom: 0px;
  z-index: -1;
}

.banner-seven .banner-seven__thumb img {
  max-width: 40vw;
}

.h-seven-text-wrapper {
  background-color: #120f23;
}

.h-seven-text-wrapper .text-slider-large-rtl {
  margin-top: 60px;
}

.h-seven-text-wrapper .text-slider-large-rtl h2 {
  direction: ltr !important;
}

.about-banner {
  padding: 270px 0px 160px;
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.about-banner::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -100px;
  content: "";
  width: 570px;
  height: 570px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 255, 223, 0.6);
  filter: blur(270px);
  z-index: -1;
}

.about-banner::before {
  position: absolute;
  top: 90%;
  transform: translateX(-50%);
  left: 0px;
  content: "";
  width: 420px;
  height: 420px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 169, 255, 0.44);
  filter: blur(270px);
  z-index: -1;
}

.about-banner .light-title {
  margin-top: 30px;
}

.about-banner .scroll-position-btn {
  position: absolute;
  bottom: 30%;
  transform: translateY(-30%);
  right: 6%;
  width: 230px;
  min-width: 230px;
  height: 230px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: var(--primary-color);
  background-color: rgba(101, 255, 75, 0.21);
  z-index: 1;
}

.about-banner .scroll-position-btn img {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  transform: translate(-50%, -50%);
  animation: rotateInfinite 24s linear infinite;
  animation-play-state: running;
  display: inline-block;
  z-index: -1;
}

.about-banner .scroll-position-btn i {
  transform: rotate(-45deg);
}

.about-banner .scroll-position-btn:hover img {
  animation-play-state: paused;
}

.service-banner {
  padding: 270px 0px 160px;
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.service-banner::before {
  position: absolute;
  top: 0%;
  left: -100px;
  content: "";
  width: 420px;
  height: 420px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(143, 75, 255, 0.44);
  filter: blur(270px);
  z-index: -1;
}

.service-banner .light-title {
  margin-top: 30px;
}

.cmn-banner {
  padding: 260px 0px 160px;
  position: relative;
  overflow-x: clip;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.cmn-banner .thumb-left {
  position: absolute;
  top: 100px;
  left: 7%;
  z-index: -1;
}

.cmn-banner .thumb-left img {
  max-width: 14vw;
  min-width: 60px;
}

.cmn-banner .thumb-right {
  position: absolute;
  bottom: 0px;
  right: 6%;
  z-index: -1;
}

.cmn-banner .thumb-right img {
  max-width: 14vw;
  min-width: 90px;
}

/* ====
 --------- (4.02) banner styles end ---------
 ==== */
/* ====
 --------- (4.03) footer styles start ---------
 ==== */
.footer {
  background-color: var(--tertiary-color);
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 20%;
  width: 40%;
  height: 50%;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 82, 255, 0.09);
  filter: blur(227px);
  z-index: -1;
}

.footer::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 20%;
  width: 40%;
  height: 50%;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 82, 255, 0.09);
  filter: blur(227px);
  z-index: -1;
}

.footer .footer__content {
  margin-top: -12px;
}

.footer .footer__content h2 {
  color: var(--white);
  text-transform: capitalize;
}

.footer .footer__content h2 span {
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  margin-left: 8px;
  display: inline-block;
}

.footer .footer__content h2 span::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4px;
  width: 110%;
  height: 65%;
  background-color: rgba(101, 255, 75, 0.3);
  z-index: -1;
  display: inline-block;
}

.footer .footer__content-cta {
  margin-top: 120px;
}

.footer .footer__nav {
  padding-top: 120px;
  padding-bottom: 40px;
  border-top: 1px solid #302e3f;
}

.footer .footer__nav-list ul {
  display: flex;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}

.footer .footer__nav-list a {
  color: var(--quinary-color);
}

.footer .footer__nav-list a:hover {
  color: var(--primary-color);
}

.footer .footer__copyright {
  padding: 40px 0px;
  border-top: 1px solid #302e3f;
}

.footer .footer__copyright-social {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}

.footer .footer__copyright-social p {
  color: var(--quinary-color);
}

.footer .footer__copyright-social a {
  width: auto;
  height: auto;
  min-width: auto;
  background-color: transparent;
  font-size: 24px;
  color: var(--primary-color);
}

.footer .footer__copyright-social a:hover {
  color: var(--white);
}

.footer .footer-thumb-one {
  position: absolute;
  top: 160px;
  left: 10%;
  z-index: -1;
}

.footer .footer-thumb-one::before {
  content: "";
  position: absolute;
  top: 60%;
  left: 60%;
  width: 100%;
  max-width: 10vw;
  height: 130px;
  background-image: url("../images/footer/shape-two.png");
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 24px;
  z-index: 1;
  animation: globalImageAnimation 6s ease-in-out infinite alternate-reverse;
}

.footer .footer-thumb-one img {
  max-width: 10vw;
  min-width: 60px;
  transform: rotate(-24deg);
}

.footer .footer-thumb-two {
  position: absolute;
  top: 160px;
  right: 10%;
  z-index: -1;
}

.footer .footer-thumb-two::before {
  content: "";
  position: absolute;
  top: 60%;
  right: 60%;
  width: 100%;
  max-width: 10vw;
  height: 130px;
  background-image: url("../images/footer/shape-one.png");
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 24px;
  z-index: 1;
  animation: globalImageAnimation 6s ease-in-out infinite alternate-reverse;
}

.footer .footer-thumb-two img {
  max-width: 10vw;
  min-width: 60px;
  transform: rotate(-154deg);
}

.footer-alter {
  background-color: white;
  overflow: visible;
  overflow-x: clip;
  position: relative;
  z-index: 1;
}

.footer-alter::before,
.footer-alter::after {
  content: none;
}

.footer-alter::after {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 0px;
  width: 13vw;
  height: 60%;
  top: unset;
  border-radius: unset;
  filter: unset;
  background: url("../images/bg-f-l.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.footer-alter .footer-alter__inner {
  padding: 80px;
  border-radius: 10px;
  background-image: url("../images/footer/footer-bg-two.png"), linear-gradient(132deg, #65ff4b 0%, #46e695 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
  position: relative;
  z-index: 1;
  margin-top: 130px;
}

.footer-alter .footer-alter__content {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footer-alter .footer-alter__content h2 {
  color: #191b1a;
  max-width: 290px;
}

.footer-alter .footer-alter__content .offcanvas-info__form {
  flex-grow: 1;
}

.footer-alter .footer-alter__content .offcanvas-info__form input {
  border-radius: 330px;
  border: 1px solid rgba(41, 44, 50, 0.19);
  background: rgba(255, 255, 255, 0.33);
  color: #191b1a;
}

.footer-alter .thumber {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -1;
  max-width: 430px;
  max-height: 400px;
}

.footer-alter .footer__nav {
  padding-top: 0px !important;
  border-top: 0px !important;
}

.footer-alter .footer__nav a {
  color: #191b1a;
}

.footer-alter .footer__nav a:hover {
  color: #191b1a;
}

.footer-alter .footer__copyright {
  border-top-color: rgba(25, 27, 26, 0.1254901961) !important;
}

.footer-alter .footer__copyright p {
  color: #191b1a;
}

.footer-alter .footer__copyright p a {
  font-weight: 500;
}

.footer-alter .footer__copyright p a:hover {
  color: #191b1a;
}

.footer-alter .footer__copyright a {
  color: #191b1a;
}

.footer-alter .footer__copyright a:hover {
  color: #191b1a;
}

.footer-seven {
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top right -80%;
}

.footer-seven::after {
  content: none;
}

.footer-seven::before {
  position: absolute;
  bottom: 0%;
  top: unset;
  left: 0px;
  transform: translateX(-60%) translateY(110%) rotate(-44deg);
  content: "";
  width: 1290px;
  height: 520px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(84, 173, 255, 0.39);
  filter: blur(130px);
  z-index: -1;
}

.footer-seven .footer__nav {
  padding-bottom: 60px;
  border-top: 0px;
}

.footer-seven .footer__nav .social a {
  width: auto;
  height: auto;
  min-width: auto;
  background-color: transparent;
  font-size: 24px;
  color: var(--secondary-color);
}

.footer-seven .footer__nav .social a:hover {
  color: var(--white);
}

.footer-seven h5 {
  color: #ffffff;
}

.footer-seven .offcanvas-info__form {
  flex-grow: 1;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.footer-seven .offcanvas-info__form input {
  border-radius: 330px;
  border: 1px solid rgba(41, 44, 50, 0.19);
  background: rgba(255, 255, 255, 0.33);
  color: #ffffff;
}

.footer-seven .offcanvas-info__form input::placeholder {
  color: #ffffff;
}

.footer-seven .offcanvas-info__form button {
  background-color: var(--primary-color);
  color: #191b1a;
}

.footer-seven .footer__copyright {
  padding: 60px 0px;
}

.footer-cmn {
  /* position: relative; */
  z-index: 1;
}

.footer-cmn::after {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: translateX(20%) translateY(20%);
  content: "";
  width: 560px;
  height: 560px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(208, 75, 255, 0.24);
  filter: blur(230px);
  z-index: -1;
}

.footer-cmn .footer-cmn__cta {
  padding: 80px 20px;
  border-radius: 20px;
  background: linear-gradient(132deg, #65ff4b 0%, #46e695 100%);
}

.footer-cmn .footer-cmn__cta .title {
  color: var(--quaternary-color);
}

.footer-cmn .footer-cmn__cta .btn {
  background-color: #191b1a;
  color: white;
  background-image: none;
  border: 0px !important;
  font-weight: 600;
}

.footer-cmn .footer-cmn__cta .btn::before {
  background-color: white;
  background-image: none;
}

.footer-cmn .footer-cmn__cta .btn:hover {
  color: #000000;
  border: 0px !important;
}

.footer-cmn::before {
  content: "";
  position: absolute;
  top: 160px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
}

.footer-cmn .footer-cmn__inner {
  padding-bottom: 80px;
}

.footer-cmn .footer-cmn__inner .gaper {
  row-gap: 40px;
}

.footer-cmn .footer__nav-list {
  margin-top: 40px;
}

.footer-cmn .footer__nav-list ul {
  display: flex;
  align-items: center;
  gap: 40px;
  row-gap: 24px;
  flex-wrap: wrap;
}

.footer-cmn .footer__nav-list a {
  color: var(--quinary-color);
}

.footer-cmn .footer__nav-list a:hover {
  color: var(--primary-color);
}

.footer-cmn .subscribe-form {
  max-width: 100%;
  margin-top: 30px;
}

.footer-cmn .subscribe-form input {
  background-color: #292c32;
}

.footer-cmn .subscribe-form button {
  background-image: linear-gradient(132deg, #f9c613 0%, #f9c613 100%);
  color: #1c1b1f;
}

.footer-cmn .footer__copyright {
  padding: 40px 0px;
  border-top: 1px solid #302e3f;
  background-color: #120f23;
}

.footer-cmn .footer__copyright-social {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}

.footer-cmn .footer__copyright-social p {
  color: var(--quinary-color);
}

.footer-cmn .footer__copyright-social a {
  width: auto;
  height: auto;
  min-width: auto;
  background-color: transparent;
  font-size: 24px;
  color: var(--white);
}

.footer-cmn .footer__copyright-social a:hover {
  color: var(--primary-color);
}

.lilu-foot::before {
  top: 0px;
}

.lilu-foot .footer-cmn__cta {
  position: relative;
  top: -150px;
  margin-bottom: -150px;
}

.lilu-review {
  padding-bottom: 310px;
}

/* ====
 --------- (4.03) footer styles end ---------
 ==== */
/* ====
 --------- (5.0) all sections styles start ---------
 ==== */
.text-slider-wrapper {
  position: relative;
  z-index: 2;
  overflow-x: clip;
  margin-bottom: 4%;
}

.text-slider-wrapper::after {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 50%;
  width: 110%;
  height: 100%;
  background-color: #40cc28;
  z-index: -1;
  transform: rotate(0deg) translateX(-50%);
}

.text-slider-wrapper .text-slider {
  padding: 24px 0px;
  background-color: var(--primary-color);
  transform: rotate(-5deg);
  z-index: 2;
  position: relative;
}

.text-slider-wrapper .text-slider::before {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 50%;
  width: 120%;
  height: 100%;
  z-index: -1;
  transform: translateX(-50%);
  background-color: var(--primary-color);
}

.text-slider-wrapper .text-slider .slick-list {
  margin: 0px -32px;
}

.text-slider-wrapper .text-slider .slick-slide {
  margin: 0px 32px;
}

.text-slider-wrapper .text-slider .text-slider__single {
  display: flex;
  align-items: center;
  gap: 64px;
}

.text-slider-wrapper .text-slider a {
  color: var(--quaternary-color);
  font-weight: 900;
  font-family: var(--inter);
}

.text-slider-wrapper .text-slider a:hover {
  color: var(--black);
}

.text-slider-wrapper .text-slider .text-stroke {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px rgba(25, 27, 26, 0.3137254902);
  position: relative;
  line-height: 1.6;
}

.text-slider-wrapper .text-slider .text-stroke::before {
  content: attr(data-text);
  position: absolute;
  width: 0%;
  height: 100%;
  white-space: pre;
  word-break: normal;
  background-color: #191b1a;
  background-clip: text;
  -webkit-background-clip: text;
  transition: width 0.7s cubic-bezier(0.39, 0.13, 0.55, 0.88);
  will-change: width;
}

.text-slider-wrapper .text-slider .text-stroke:hover::before {
  width: 100%;
}

.craft {
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.craft .anime-one {
  position: absolute;
  top: 0px;
  right: -40px;
  min-width: 40px;
  max-width: 10vw;
  animation: globalImageAnimation 6s ease-in-out infinite alternate-reverse;
}

.craft::before {
  position: absolute;
  bottom: 80px;
  right: -180px;
  content: "";
  width: 600px;
  height: 600px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 255, 223, 0.24);
  filter: blur(227px);
  z-index: -1;
}

.craft::after {
  position: absolute;
  bottom: -250px;
  transform: translateX(-50%);
  left: 0px;
  content: "";
  width: 430px;
  height: 430px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 169, 255, 0.44);
  filter: blur(272px);
  z-index: -1;
}

.craft-alt {
  overflow: hidden;
}

.craft-alt::before {
  position: absolute;
  top: 10%;
  right: 0px;
  transform: translateX(50%) rotate(-44deg);
  content: "";
  width: 1290px;
  height: 520px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(149, 84, 255, 0.2);
  filter: blur(130px);
  z-index: -1;
}

.craft-alt::after {
  position: absolute;
  top: 0%;
  left: 0px;
  transform: translateX(-50%) rotate(-44deg);
  content: "";
  width: 1290px;
  height: 520px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(211, 84, 255, 0.2);
  filter: blur(130px);
  z-index: -1;
}

.text-slider-large-wrapper .slick-list {
  margin: -40px -24px -10px;
}

.text-slider-large-wrapper .slick-slide {
  margin: 0px 24px;
}

.text-slider-large-wrapper .slick-track {
  display: flex;
  align-items: center;
}

.text-slider-large-wrapper h2 {
  font-family: var(--inter);
  font-weight: 700;
  position: relative;
  padding-left: 60px;
  z-index: 1;
}

.text-slider-large-wrapper h2::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  width: 12px;
  height: 12px;
  min-width: 12px;
  background-color: var(--primary-color);
  border-radius: 50%;
  z-index: 1;
}

.text-slider-large-wrapper h2 a {
  color: var(--primary-color);
}

.text-slider-large-wrapper .text-stroke {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.3137254902);
  position: relative;
  transition: all 1.7s cubic-bezier(0.39, 0.13, 0.55, 0.88);
  line-height: 1.3;
  padding-left: 16px;
}

.text-slider-large-wrapper .text-stroke::before {
  content: attr(data-text);
  position: absolute;
  width: 0%;
  height: 100%;
  white-space: pre;
  word-break: normal;
  background-color: var(--primary-color);
  background-clip: text;
  -webkit-background-clip: text;
  transition: width 0.7s cubic-bezier(0.39, 0.13, 0.55, 0.88);
  will-change: width;
}

.text-slider-large-wrapper .text-stroke:hover {
  -webkit-text-stroke-color: var(--primary-color);
}

.text-slider-large-wrapper .text-stroke:hover::before {
  width: 100%;
}

.alter-text-large h2 {
  color: var(--primary-color);
}

.tools {
  position: relative;
  z-index: 1;
}

.tools::before {
  position: absolute;
  top: 80px;
  right: -80px;
  content: "";
  width: 430px;
  height: 430px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(154, 75, 255, 0.21);
  filter: blur(272px);
  z-index: -1;
}

.gen {
  position: relative;
  z-index: 1;
}

.gen::before {
  content: "";
  position: absolute;
  top: 100px;
  right: 12%;
  z-index: -1;
  background-image: url("../images/bg-line.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 16vw;
  min-width: 120px;
  height: 400px;
  min-height: 200px;
}

.gen::after {
  position: absolute;
  top: 100px;
  left: -300px;
  content: "";
  width: 900px;
  height: 900px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 255, 223, 0.09);
  filter: blur(272px);
  z-index: -1;
}

.partner {
  position: relative;
  z-index: 1;
}

.partner .sub-title-two::before {
  left: 50%;
  transform: translateX(-50%);
}

.partner .slick-track {
  display: flex;
  align-items: center;
}

.partner .partner__slider-single {
  text-align: center;
}

.partner .partner__slider-single img {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
  max-width: 10vw;
  min-width: 80px;
}

.partner .partner__slider-single img:hover {
  filter: none;
}

.partner .slick-center img {
  filter: none;
}

.sponsor__slider .slick-track {
  display: flex;
  align-items: center;
}

.service-slider {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  z-index: 2;
}

.service-slider .service-slider__item {
  width: 20vw;
  min-width: 20vw;
  height: 100%;
  position: relative;
}

.service-slider .service-slider__item a {
  width: 100%;
  height: 100%;
}

.service-slider .service-slider__item img {
  width: 100%;
  height: 100%;
}

.service-slider .service-slider__item .video-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 260px;
  min-width: 260px;
  height: 260px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  margin-left: auto;
  margin-right: auto;
}

.service-slider .service-slider__item .video-frame img {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0px;
  animation: rotateInfinite 24s linear infinite;
  animation-play-state: running;
}

.service-slider .service-slider__item .video-frame i {
  width: 150px;
  min-width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: var(--quaternary-color);
}

.service-slider .service-slider__item .video-frame:hover img {
  animation-play-state: paused;
}

.unlock {
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.unlock::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -300px;
  content: "";
  width: 900px;
  height: 900px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 255, 223, 0.09);
  filter: blur(227px);
  z-index: -1;
}

.unlock::after {
  content: "";
  position: absolute;
  top: 110px;
  left: -20px;
  width: 200px;
  height: 200px;
  background-image: url("../images/unlock-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.unlock .unlock__thumb {
  position: relative;
  z-index: 1;
}

.unlock .unlock__thumb::before {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: -60px;
  width: 100%;
  height: 100%;
  background-image: url("../images/line-two.png");
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
}

.overview .overview__single {
  padding: 32px;
  border: 1px solid #302e3f;
  border-radius: 10px;
  border-top-right-radius: 100px;
  background-color: #100d21;
  transition: var(--transition);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.overview .overview__single::before {
  content: "";
  position: absolute;
  inset: 0px;
  border-radius: inherit;
  width: 0px;
  background-color: var(--primary-color);
  z-index: -1;
  transition: width 0.3s cubic-bezier(0.39, 0.13, 0.55, 0.88);
  will-change: width;
}

.overview .overview__single .overview__thumb {
  margin-bottom: 32px;
}

.overview .overview__single .overview__thumb img {
  transition: filter 0.3s ease;
}

.overview .overview__single h4 {
  color: var(--white);
  font-weight: 600;
}

.overview .overview__single h4,
.overview .overview__single p {
  transition: var(--transition);
}

.overview .overview__single p {
  margin-top: 16px;
}

.overview .overview__single:hover {
  border-color: var(--primary-color);
}

.overview .overview__single:hover::before {
  width: 100%;
}

.overview .overview__single:hover .overview__thumb img {
  filter: brightness(0) saturate(100%) invert(7%) sepia(6%) saturate(579%) hue-rotate(97deg) brightness(98%) contrast(91%);
}

.overview .overview__single:hover h4,
.overview .overview__single:hover p {
  color: var(--quaternary-color);
}

.overview .overview__single-active {
  border-color: var(--primary-color);
}

.overview .overview__single-active::before {
  right: 0px;
  left: unset;
  width: 100%;
}

.overview .overview__single-active .overview__thumb img {
  filter: brightness(0) saturate(100%) invert(7%) sepia(6%) saturate(579%) hue-rotate(97deg) brightness(98%) contrast(91%);
}

.overview .overview__single-active h4,
.overview .overview__single-active p {
  color: var(--quaternary-color);
}

.lilu-view {
  background-color: #120f23;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.lilu-view::after {
  position: absolute;
  top: 20%;
  transform: translateY(-20%);
  right: -100px;
  content: "";
  width: 570px;
  height: 570px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 255, 223, 0.24);
  filter: blur(270px);
  z-index: -1;
}

.video-s {
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.video-s::before {
  position: absolute;
  bottom: -300px;
  right: -400px;
  content: "";
  width: 900px;
  height: 900px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 255, 223, 0.09);
  filter: blur(227px);
  z-index: -1;
}

.video-s .video-s__inner {
  padding: 80px;
  border-radius: 40px;
  border: 1px solid #302e3f;
}

.video-s video {
  width: 100%;
  min-height: 300px;
  border-radius: 40px;
  object-fit: cover;
}

.pricing {
  background-size: auto;
  background-repeat: no-repeat;
  background-position: left 10% top 40%;
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.pricing::before {
  position: absolute;
  top: -200px;
  left: -300px;
  content: "";
  width: 900px;
  height: 900px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(208, 75, 255, 0.09);
  filter: blur(227px);
  z-index: -1;
}

.pricing::after {
  position: absolute;
  top: 0px;
  transform: translateX(50%);
  right: 0px;
  content: "";
  width: 900px;
  height: 900px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 169, 255, 0.09);
  filter: blur(272px);
  z-index: -1;
}

.pricing .section__header .section__content-cta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 5px;
  padding: 10px;
  background-color: #1f1c2f;
}

.pricing .section__header button {
  padding: 8px 40px;
  font-size: 16px;
  color: var(--white);
  border-radius: 5px;
}

.pricing .section__header .price-btn-active {
  background-color: #f9c613;
  color: var(--quaternary-color);
}

.pricing .pricing__single {
  padding: 40px 32px;
  background-color: var(--tertiary-color);
  border-radius: 20px;
  border-top-right-radius: 140px;
  margin-top: 80px;
}

.pricing .pricing__intro .primary-text {
  color: #ff4bb7;
  margin-bottom: 24px;
  font-weight: 600;
}

.pricing .pricing__intro .premium {
  color: #4bd4ff;
}

.pricing .pricing__intro h2 {
  color: var(--white);
  font-weight: 700;
  white-space: nowrap;
}

.pricing .pricing__intro h2 span {
  font-size: 18px;
  color: #b1b0b6;
  line-height: 1;
  font-weight: 400;
}

.pricing hr {
  margin: 40px 0px;
  height: 1px;
  border: 0px;
  opacity: 0.1000000015;
  background: #fff;
}

.pricing li {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #b1b0b6;
  margin-bottom: 16px;
}

.pricing li:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.pricing li i {
  font-size: 24px;
}

.pricing .btn {
  width: 100%;
}

.pricing .pricing__single-active {
  margin-top: 0px;
  border-right: 1px solid #f9c613;
  border-top: 1px solid #f9c613;
}

.pricing .pricing__single-active .pricing__intro .primary-text {
  color: #ffd584;
}

.pri-alt {
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.pri-alt::before {
  position: absolute;
  top: 30%;
  left: 0px;
  transform: translateX(-50%) rotate(-44deg);
  content: "";
  width: 1290px;
  height: 520px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(128, 84, 255, 0.2);
  filter: blur(130px);
  z-index: -1;
}

.blog .blog__single {
  padding: 20px 20px 30px;
  background-color: var(--tertiary-color);
  border-radius: 20px;
  transition: var(--transition);
}

.blog .blog__single h4 {
  margin-top: 20px;
  max-width: 380px;
}

.blog .blog__single h4 a {
  color: white;
  font-weight: 600;
}

.blog .blog__single h4 a:hover {
  color: var(--quaternary-color);
}

.blog .blog__single:hover {
  background-color: var(--primary-color);
}

.blog .blog__single:hover h4 a {
  color: var(--quaternary-color);
}

.blog .blog__single:hover .blog__single-meta a,
.blog .blog__single:hover .blog__single-meta span {
  color: #4c4a59;
}

.blog .blog__single-thumb {
  margin-bottom: 20px;
}

.blog .blog__single-thumb a {
  width: 100%;
  border-radius: 20px;
}

.blog .blog__single-thumb img {
  width: 100%;
  border-radius: 20px;
  min-height: 200px;
}

.blog .blog__single-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.blog .blog__single-meta span {
  font-size: 16px;
  font-weight: 400;
  transition: var(--transition);
}

.blog .blog__single-meta a {
  color: var(--quinary-color);
  font-size: 16px;
  font-weight: 400;
}

.blog .blog__single-meta a:hover {
  color: #4c4a59;
}

.blog .blog__single-active {
  background-color: var(--primary-color);
}

.blog .blog__single-active h4 a {
  color: var(--quaternary-color);
}

.blog .blog__single-active .blog__single-meta a,
.blog .blog__single-active .blog__single-meta span {
  color: #4c4a59;
}

.counter .counter__inner {
  display: flex;
  align-items: center;
  row-gap: 70px;
  column-gap: 30px;
  justify-content: space-between;
}

.counter .counter__single {
  text-align: center;
}

.counter .counter__single .odometer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter .counter__single .light-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.counter .counter__single span {
  font-weight: 700;
}

.counter .counter__single .prefix {
  color: #f9c613;
}

.counter .counter__single p {
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
}

.counter-alter .counter__single h2 {
  color: #191b1a;
}

.counter-alter .counter__single .prefix {
  color: var(--secondary-color);
}

.overview-two .gaper {
  row-gap: 60px;
}

.overview-two a .arrow {
  position: relative;
  display: block;
  width: 50px;
  margin-bottom: 10px;
}

.overview-two a .arrow::before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 2px;
  width: calc(100% - 4px);
  background-color: #414141;
  transition: var(--transition);
}

.overview-two a .arrow::after {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  right: -2px;
  top: 50%;
  transform: translateY(-48%);
  font-size: 24px;
  color: #414141;
  transition: var(--transition);
}

.overview-two a:hover .arrow::before {
  background-color: var(--primary-color);
}

.overview-two a:hover .arrow::after {
  color: var(--primary-color);
}

.overview-two .thumb {
  margin-bottom: 30px;
}

.overview-two h4 {
  margin-bottom: 20px;
}

.overview-two h4 a {
  color: var(--white);
}

.overview-two h4 a:hover {
  color: var(--primary-color);
}

.overview-two .overview-two__single:hover h4 a {
  color: var(--primary-color);
}

.overview-two .overview-two__single:hover a .arrow::before {
  background-color: var(--primary-color);
}

.overview-two .overview-two__single:hover a .arrow::after {
  color: var(--primary-color);
}

.review {
  background-color: #120f23;
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.review::before {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translateX(80%) translateY(-20%);
  content: "";
  width: 560px;
  height: 560px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(208, 75, 255, 0.24);
  filter: blur(230px);
  z-index: -1;
}

.review::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: translateX(-80%) translateY(10%);
  content: "";
  width: 560px;
  height: 560px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 255, 223, 0.24);
  filter: blur(230px);
  z-index: -1;
}

.review .review__slider-single {
  padding: 30px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3019607843);
}

.review .review__meta {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.review .review__meta .thumb {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.review .review__meta .thumb img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.review .review__meta h5 {
  margin-bottom: 6px;
  color: white;
}

.text-brief {
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.text-brief::after {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 830px;
  height: 830px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3764705882);
  filter: blur(272px);
  z-index: -1;
}

.text-brief .t-br-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 16px;
  flex-wrap: wrap;
}

.text-brief h2,
.text-brief h3,
.text-brief h4 {
  text-transform: capitalize;
}

.text-brief .t-br-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 16px;
  flex-wrap: wrap;
}

.text-brief .t-br-three {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  flex-wrap: wrap;
}

.revolution .gaper {
  row-gap: 40px;
}

.revolution .thumb {
  margin-bottom: 30px;
  position: relative;
}

.revolution .thumb .tag {
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  width: 70px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: #191b1a;
  border-radius: 50px;
}

.revolution .thumb a {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 20px;
  overflow: hidden;
}

.revolution .thumb a img {
  width: 100%;
  border-radius: 20px;
  min-height: 240px;
  transition: var(--transition);
}

.revolution .thumb:hover .arrow::before {
  background-color: var(--primary-color);
}

.revolution .thumb:hover .arrow::after {
  color: var(--primary-color);
}

.revolution .content a {
  color: white;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.revolution .content a span {
  line-height: 1;
}

.revolution .content a:hover {
  color: var(--primary-color);
}

.revolution .content .arrow {
  position: relative;
  display: block;
  width: 70px;
}

.revolution .content .arrow::before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 1px;
  width: calc(100% - 4px);
  background-color: #414141;
  transition: var(--transition);
}

.revolution .content .arrow::after {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-48%);
  font-size: 18px;
  color: #414141;
  transition: var(--transition);
}

.revolution .revolution__single:hover .thumb a {
  border: 1px solid var(--primary-color);
  box-shadow: 0px 4px 26px 0px rgba(101, 255, 75, 0.29);
}

.revolution .revolution__single:hover .thumb a img {
  transform: scale(1.1);
}

.revolution .revolution__single:hover .content a {
  color: var(--primary-color);
}

.revolution .revolution__single:hover .content .arrow::before {
  background-color: var(--primary-color);
}

.revolution .revolution__single:hover .content .arrow::after {
  color: var(--primary-color);
}

.empower .content-wrapper {
  background-image: linear-gradient(132deg, #65ff4b 0%, #46e695 100%);
  clip-path: polygon(8.684% 36.644%, 8.684% 36.644%, 8.784% 36.074%, 8.902% 35.543%, 9.037% 35.054%, 9.186% 34.61%, 9.349% 34.213%, 9.524% 33.867%, 9.71% 33.573%, 9.906% 33.336%, 10.111% 33.158%, 10.323% 33.041%, 93.236% 0.326%, 93.236% 0.326%, 93.575% 0.273%, 93.904% 0.38%, 94.218% 0.633%, 94.512% 1.024%, 94.782% 1.541%, 95.023% 2.174%, 95.229% 2.912%, 95.398% 3.744%, 95.523% 4.659%, 95.599% 5.648%, 99.962% 92.755%, 99.962% 92.755%, 99.985% 93.885%, 99.942% 94.973%, 99.839% 96.002%, 99.681% 96.953%, 99.474% 97.808%, 99.222% 98.548%, 98.931% 99.156%, 98.605% 99.613%, 98.252% 99.9%, 97.875% 100%, 2.168% 100%, 2.168% 100%, 1.722% 99.862%, 1.315% 99.469%, 0.952% 98.854%, 0.641% 98.049%, 0.389% 97.086%, 0.203% 95.998%, 0.089% 94.816%, 0.055% 93.574%, 0.108% 92.303%, 0.253% 91.035%, 8.684% 36.644%);
  padding: 80px 140px;
}

.empower .empower__thumb {
  text-align: center;
  position: relative;
}

.empower .empower__thumb img {
  transform: translateY(140px);
  margin-top: -140px;
  z-index: -1;
  min-height: 240px;
}

.empower .content {
  text-align: end;
  transform: rotate(-10deg);
  margin-bottom: -40px;
}

.empower .content .light-title {
  color: #000000;
  font-weight: 700;
  margin-bottom: 16px;
}

.empower .content h2 {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px rgba(25, 27, 26, 0.3137254902);
  font-family: var(--inter);
}

.gaming .gaming__single-alt {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 30px;
  background-color: #120f23;
  border-radius: 20px;
  height: 100%;
}

.gaming .gaming__single-alt .thumb a {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.gaming .gaming__single-alt .thumb img {
  width: 100%;
  max-width: 274px;
  min-width: 274px;
  min-height: 240px;
  border-radius: 20px;
  transition: var(--transition);
}

.gaming .gaming__single-alt .content {
  flex-grow: 1;
}

.gaming .gaming__single-alt:hover img {
  transform: scale(1.2);
}

.gaming .tag {
  padding: 8px 16px;
  font-size: 14px;
  background-color: rgba(101, 255, 75, 0.4392156863);
  color: white;
  border-radius: 50px;
  margin-bottom: 30px;
}

.gaming h4 a {
  color: white;
  font-weight: 700;
}

.gaming h4 a:hover {
  color: var(--primary-color);
}

.gaming .info {
  margin-top: 30px;
}

.gaming .info p:nth-last-of-type(1) {
  margin-top: 8px;
}

.gaming hr {
  margin: 30px 0px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.062745098);
}

.gaming .cta a {
  text-decoration: underline;
  color: white;
}

.gaming .cta a:hover {
  color: var(--primary-color);
}

.gaming .gaming__single {
  height: 100%;
}

.gaming .gaming__single .thumb {
  height: 100%;
}

.gaming .gaming__single .thumb a {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  height: 100%;
}

.gaming .gaming__single .thumb img {
  width: 100%;
  min-height: 240px;
  height: 100%;
  border-radius: 20px;
  transition: var(--transition);
}

.gaming .gaming__single:hover img {
  transform: scale(1.2);
}

.pricing--secondary .pricing__single {
  display: flex;
  gap: 30px;
  background-color: #120f23;
  margin-top: 0px;
  border-radius: 20px;
}

.pricing--secondary .pricing__single .btn {
  width: auto;
}

.pricing--secondary .pricing__intro {
  max-width: 240px;
}

.pricing--secondary .pricing__intro p {
  margin-bottom: 30px;
}

.pricing--secondary .pricing__content {
  flex-grow: 1;
  padding-left: 30px;
  border-left: 1px solid #302e3f;
}

.pricing--secondary .pricing__content ul {
  margin: 20px 0px 30px;
}

.showcase a {
  width: 100%;
}

.showcase img {
  width: 100%;
}

.showcase .slick-slide {
  margin: 0px !important;
}

.showcase .slick-list {
  margin: 0px !important;
}

.showcase .slick-track {
  transition-delay: 0s !important;
}

.product-filter .product-item-wrapper {
  margin-top: 80px;
}

.product-filter .product-filter__wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.product-filter .product-filter__wrapper button {
  padding: 8px 16px;
  background-color: #302e3f;
  border-radius: 10px;
  font-size: 16px;
}

.product-filter .product-filter__wrapper button:hover {
  color: #030015;
  background-color: var(--primary-color);
}

.product-filter .product-filter__wrapper .active {
  color: #030015;
  background-color: var(--primary-color);
}

.product-filter .product-search form {
  position: relative;
}

.product-filter .product-search form button {
  position: absolute;
  top: 15%;
  left: 0px;
  font-size: 18px;
  color: white;
}

.product-filter .product-search form button:hover {
  color: var(--primary-color);
}

.product-filter .product-search input {
  width: 100%;
  background-color: transparent;
  padding: 0px 16px 20px 36px;
  border-bottom: 1px solid white;
  color: white;
}

.product-filter .product-search input::placeholder {
  color: white;
}

.product-filter .product-search input:focus {
  border-color: var(--primary-color);
}

.product-filter .thumb {
  position: relative;
}

.product-filter .thumb a {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.product-filter .thumb a img {
  width: 100%;
  min-height: 240px;
  border-radius: 10px;
  transition: var(--transition);
}

.product-filter .thumb button {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5019607843);
  color: #191b1a;
  font-size: 24px;
  font-weight: 700;
  position: absolute;
  top: 30px;
  right: 30px;
}

.product-filter .content {
  padding: 24px 0px;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #3d3b4b;
}

.product-filter .content h4 a {
  color: white;
}

.product-filter .content h4 a:hover {
  color: var(--primary-color);
}

.product-filter .product-filter__single:hover .thumb img {
  transform: scale(1.1);
}

.product-filter .product-filter__single:hover .thumb button {
  background-color: var(--primary-color);
}

.product-filter .grid-item-main {
  margin-bottom: 40px;
  margin-left: 12px;
  margin-right: 12px;
  width: calc(33.33% - 24px);
}

.product-filter .masonry-grid {
  margin-bottom: -40px;
  margin-left: -12px;
  margin-right: -12px;
  display: flex;
  justify-content: space-between;
}

.category .category-overview__single a {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 7px;
}

.category .category-overview__single a span {
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: #191b1a;
  outline-offset: 7px;
  outline: 1px solid #ffffff;
  font-weight: 700;
}

.category .category-overview__single a img {
  width: 80%;
  height: 80%;
  border-radius: 10px;
  position: absolute;
  inset: 0px;
  left: unset;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
}

.category hr {
  margin: 60px 0px;
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1254901961);
}

.category .category-filter {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.category .category-filter button {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 30px;
}

.category .category-filter button img {
  border-radius: 10px;
}

.category .category-filter button:hover {
  background-color: var(--primary-color);
  color: #191b1a;
}

.category .category-filter .active {
  background-color: var(--primary-color);
  color: #191b1a;
}

.category .category-masonry {
  margin-bottom: -40px;
}

.category .category-item {
  margin-bottom: 40px;
}

.category .category__single {
  padding: 20px;
  background-color: #100d21;
  border-radius: 20px;
}

.category .category__single .thumb {
  margin-bottom: 20px;
  position: relative;
}

.category .category__single .thumb .thumb-img {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.category .category__single .thumb .thumb-img img {
  min-height: 180px;
  transition: var(--transition);
  border-radius: 10px;
  width: 100%;
}

.category .category__single .thumb .tag {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  background: #000000;
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 30px;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.category .category__single .thumb .tag img {
  border-radius: 10px;
}

.category .category__single .thumb .tag:hover {
  background-color: var(--primary-color);
  color: #191b1a;
}

.category .category__single .content {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.category .category__single .content a {
  font-weight: 600;
  color: white;
}

.category .category__single .content a:hover {
  color: var(--primary-color);
}

.category .category__single hr {
  margin: 20px 0px;
  background-color: rgba(255, 255, 255, 0.1254901961);
}

.category .category__single .meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
}

.category .category__single .meta-info {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.category .category__single .meta-info p {
  color: white;
}

.category .category__single .meta-info img {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category .category__single .meta-review {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}

.category .category__single .meta-review i {
  font-size: 14px;
  color: #ffd584;
  cursor: pointer;
}

.category .category__single:hover .thumb-img img {
  transform: scale(1.1);
}

.new-prompts .category__single,
.best-prompts .category__single,
.chat-prompts .category__single {
  padding: 20px;
  background-color: #100d21;
  border-radius: 20px;
}

.new-prompts .category__single .thumb,
.best-prompts .category__single .thumb,
.chat-prompts .category__single .thumb {
  margin-bottom: 20px;
  position: relative;
}

.new-prompts .category__single .thumb .thumb-img,
.best-prompts .category__single .thumb .thumb-img,
.chat-prompts .category__single .thumb .thumb-img {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.new-prompts .category__single .thumb .thumb-img img,
.best-prompts .category__single .thumb .thumb-img img,
.chat-prompts .category__single .thumb .thumb-img img {
  min-height: 180px;
  transition: var(--transition);
  border-radius: 10px;
  width: 100%;
}

.new-prompts .category__single .thumb .tag,
.best-prompts .category__single .thumb .tag,
.chat-prompts .category__single .thumb .tag {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  background: #000000;
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 30px;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.new-prompts .category__single .thumb .tag img,
.best-prompts .category__single .thumb .tag img,
.chat-prompts .category__single .thumb .tag img {
  border-radius: 10px;
}

.new-prompts .category__single .thumb .tag:hover,
.best-prompts .category__single .thumb .tag:hover,
.chat-prompts .category__single .thumb .tag:hover {
  background-color: var(--primary-color);
  color: #191b1a;
}

.new-prompts .category__single .content,
.best-prompts .category__single .content,
.chat-prompts .category__single .content {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.new-prompts .category__single .content a,
.best-prompts .category__single .content a,
.chat-prompts .category__single .content a {
  font-weight: 600;
  color: white;
}

.new-prompts .category__single .content a:hover,
.best-prompts .category__single .content a:hover,
.chat-prompts .category__single .content a:hover {
  color: var(--primary-color);
}

.new-prompts .category__single hr,
.best-prompts .category__single hr,
.chat-prompts .category__single hr {
  margin: 20px 0px;
  background-color: rgba(255, 255, 255, 0.1254901961);
}

.new-prompts .category__single .meta,
.best-prompts .category__single .meta,
.chat-prompts .category__single .meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
}

.new-prompts .category__single .meta-info,
.best-prompts .category__single .meta-info,
.chat-prompts .category__single .meta-info {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.new-prompts .category__single .meta-info p,
.best-prompts .category__single .meta-info p,
.chat-prompts .category__single .meta-info p {
  color: white;
}

.new-prompts .category__single .meta-info img,
.best-prompts .category__single .meta-info img,
.chat-prompts .category__single .meta-info img {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-prompts .category__single .meta-review,
.best-prompts .category__single .meta-review,
.chat-prompts .category__single .meta-review {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}

.new-prompts .category__single .meta-review i,
.best-prompts .category__single .meta-review i,
.chat-prompts .category__single .meta-review i {
  font-size: 14px;
  color: #ffd584;
  cursor: pointer;
}

.new-prompts .category__single:hover .thumb-img img,
.best-prompts .category__single:hover .thumb-img img,
.chat-prompts .category__single:hover .thumb-img img {
  transform: scale(1.1);
}

.new-prompts .cta,
.best-prompts .cta,
.chat-prompts .cta {
  margin-top: 30px;
}

.new-prompts .cta .btn,
.best-prompts .cta .btn,
.chat-prompts .cta .btn {
  width: 100%;
  justify-content: center;
}

.feature .feature__single {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #100d21;
  padding: 20px;
}

.feature .feature__single:hover .feature__thumb img {
  transform: scale(1.1);
}

.feature .thumb {
  position: relative;
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.feature .thumb img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.feature .thumb .check {
  position: absolute;
  bottom: 6px;
  right: 0px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0057ff;
}

.feature .thumb .check i {
  color: white;
}

.feature .thumb-content {
  text-align: center;
  margin-top: 20px;
}

.feature .thumb-content p {
  color: white;
}

.feature .thumb-content p:nth-last-of-type(1) {
  margin-top: 12px;
}

.feature .thumb-content p:nth-last-of-type(1) span {
  color: #ffd584;
}

.feature hr {
  margin: 30px 0px;
  background-color: rgba(255, 255, 255, 0.1254901961);
}

.feature .feature__thumb a {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.feature .feature__thumb a img {
  width: 100%;
  border-radius: 10px;
  transition: var(--transition);
  min-height: 180px;
}

.n-level {
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.n-level .n-level__thumb {
  display: block;
}

.b-text-slider .l-t {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px #c0bfc5;
  font-family: var(--inter);
}

.revolution-f {
  position: relative;
  overflow-x: clip;
  z-index: 1;
}

.revolution-f::before {
  content: "";
  position: absolute;
  top: -150px;
  left: 0px;
  width: 10vw;
  height: 70%;
  background-image: url("../images/bg-f-l.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.revolution-f::after {
  position: absolute;
  bottom: -100px;
  right: 0%;
  content: "";
  width: 570px;
  height: 570px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(219, 75, 255, 0.24);
  filter: blur(272px);
  z-index: -1;
}

.revolution-f .revolution-f__single {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(89, 87, 101, 0.1882352941);
  position: relative;
  z-index: 1;
}

.revolution-f .revolution-f__single:nth-last-of-type(1) {
  border-bottom: 1px solid rgba(89, 87, 101, 0.1882352941);
}

.revolution-f .revolution-f__single .thumb {
  padding: 20px 20px;
  text-align: center;
  min-width: 310px;
  max-width: 310px;
}

.revolution-f .revolution-f__single .thumb p {
  font-weight: 700;
  color: #191b1a;
}

.revolution-f .revolution-f__single .content {
  flex-grow: 1;
  padding: 60px 80px;
  position: relative;
  border-left: 1px solid rgba(89, 87, 101, 0.1882352941);
}

.revolution-f .revolution-f__single .content a {
  font-weight: 700;
  color: #1f1c2f;
}

.revolution-f .revolution-f__single .case-study-hover {
  width: 206px;
  height: 284px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(20deg);
  inset-inline-start: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position-x: 75%;
  border-radius: 30px;
  opacity: 0;
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  margin: -200px 0 0 -50px;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
}

.revolution-f .revolution-f__single:hover .case-study-hover {
  opacity: 1;
}

.alter-l-text a {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #191b1a !important;
  font-family: var(--inter);
  font-weight: 900;
  text-transform: uppercase;
}

.alter-l-text a span {
  color: var(--primary-color) !important;
}

.alter-l-text h2::before {
  background-color: #191b1a !important;
}

.h-chatbot {
  background-color: #120f23;
  position: relative;
  z-index: 1;
  overflow-x: clip;
  background-repeat: no-repeat;
  background-size: 35vw;
  background-position: right top;
}

.h-chatbot .title {
  color: white;
}

.h-chatbot p {
  color: #b1b0b6;
}

.h-chatbot .h-chatbot__thumb {
  display: block;
}

.h-unlock {
  position: relative;
  overflow-x: clip;
  z-index: 1;
}

.h-unlock::before {
  content: "";
  position: absolute;
  top: -150px;
  right: 0px;
  width: 10vw;
  height: 70%;
  background-image: url("../images/h-unlock-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
  z-index: -1;
}

.h-unlock .h-unlock__thumb {
  position: relative;
  display: block;
}

.h-unlock .h-unlock__thumb img {
  min-height: 300px;
  border-radius: 10px;
}

.h-unlock .h-unlock__thumb .h-u-t-content {
  padding: 30px;
  border-radius: 10px 120px 10px 10px;
  background: linear-gradient(132deg, #65ff4b 0%, #46e695 100%);
  max-width: 310px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.h-unlock .h-unlock__thumb .h-u-t-content h2 {
  font-weight: 700;
  color: #191b1a;
}

.blog-alter .blog__single {
  border-radius: 20px;
  border: 1px solid #e7e9ee;
  background: #fafafc;
}

.blog-alter .blog__single h4 a {
  color: var(--quaternary-color);
}

.blog-alter .blog__single .blog__single-meta a,
.blog-alter .blog__single .blog__single-meta span {
  color: #4c4a59;
}

.blog-alter .blog__single-active {
  border: 1px solid #e7e9ee !important;
  background: #fafafc !important;
  box-shadow: 0px 4px 53px 0px rgba(103, 103, 103, 0.25) !important;
}

.blog-alter .blog__single-active h4 a {
  color: var(--quaternary-color);
}

.blog-alter .blog__single-active .blog__single-meta a,
.blog-alter .blog__single-active .blog__single-meta span {
  color: #4c4a59;
}

.news-alter .gaper {
  row-gap: 40px;
}

.news-alter .news-alter__single .thumb {
  margin-bottom: 20px;
  position: relative;
}

.news-alter .news-alter__single .thumb a {
  width: 100%;
  box-shadow: 0px 4px 8px 0px rgba(118, 118, 118, 0.25);
  overflow: hidden;
}

.news-alter .news-alter__single .thumb a img {
  width: 100%;
  transition: var(--transition);
  min-height: 240px;
}

.news-alter .news-alter__single .thumb .tags {
  width: 100px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: var(--primary-color);
  color: #191b1a;
  font-weight: 600;
  border-radius: 5px;
}

.news-alter .news-alter__single .meta {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.news-alter .news-alter__single .meta span {
  color: #595765;
  font-size: 16px;
}

.news-alter .news-alter__single h4 a {
  font-weight: 600;
  color: #191b1a;
}

.news-alter .news-alter__single .cta {
  margin-top: 30px;
}

.news-alter .news-alter__single .cta a {
  color: #595765;
}

.news-alter .news-alter__single .cta a:hover {
  color: #191b1a;
}

.news-alter .news-alter__single:hover a img {
  transform: scale(1.1);
}

.news-alter .news-alter-single-alt {
  height: 100%;
}

.news-alter .news-alter-single-alt .thumb {
  margin-bottom: 0px;
  height: 100%;
}

.news-alter .news-alter-single-alt .thumb a {
  height: 100%;
}

.news-alter .news-alter-single-alt .thumb img {
  height: 100%;
}

.news-alter .news-alter-single-alt hr {
  height: 1px;
  opacity: 0.2;
  background: #191b1a;
  margin: 40px 0px;
}

.news-alter .news-alter-single {
  display: flex;
  align-items: center;
  gap: 24px;
}

.news-alter .news-alter-single .meta span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.news-alter .news-alter-single .thumb {
  max-width: 230px;
  min-width: 230px;
  margin-bottom: 0px;
}

.news-alter .news-alter-single .thumb img {
  max-height: 230px;
  min-height: 180px;
  transform: scale(1) !important;
}

.publisher .publisher__single {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.publisher .publisher__single h5 {
  color: #191b1a;
  font-weight: 600;
  margin-top: 24px;
}

.publisher .publisher__single .thumb {
  width: 130px;
  min-width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 6px solid #65ff4b;
}

.publisher .publisher__single .thumb img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.h-s-poster .news-alter__single {
  position: relative;
}

.h-s-poster .news-alter__single .thumb {
  margin-bottom: 0px;
}

.h-s-poster .news-alter__single .thumb a {
  width: 100%;
  box-shadow: 0px 4px 8px 0px rgba(118, 118, 118, 0.25);
  overflow: hidden;
  border-radius: 20px;
}

.h-s-poster .news-alter__single .thumb a img {
  width: 100%;
  transition: var(--transition);
  min-height: 340px;
  border-radius: 20px;
}

.h-s-poster .news-alter__single .thumb .tags {
  width: 100px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60px;
  left: 60px;
  background-color: var(--primary-color);
  color: #191b1a;
  font-weight: 600;
  border-radius: 5px;
}

.h-s-poster .news-alter__single .content {
  position: absolute;
  bottom: 60px;
  left: 60px;
  right: 60px;
}

.h-s-poster .news-alter__single h2 a {
  font-weight: 600;
  color: white;
  display: block;
}

.h-s-poster .news-alter__single:hover a img {
  transform: scale(1.1);
}

.s-journey {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
}

.s-journey .section__content p {
  position: relative;
  padding-left: 20px;
}

.s-journey .section__content p::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 2px;
  height: 100%;
  background-color: var(--secondary-color);
}

.s-journey .s-journey__thumb img {
  width: 100%;
  border-radius: 20px 240px 20px 20px;
  min-height: 360px;
}

.s-journey .w-r {
  position: relative;
  margin-bottom: 75px;
}

.s-journey .w-r .scroll-position-btn {
  width: 150px;
  min-width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #191b1a;
  background-color: var(--primary-color);
  z-index: 1;
  position: absolute;
  bottom: -75px;
  right: 100px;
}

.s-journey .w-r .scroll-position-btn i {
  transform: rotate(-30deg);
}

.s-journey .w-r .scroll-position-btn img {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  transform: translate(-50%, -50%);
  animation: rotateInfinite 24s linear infinite;
  animation-play-state: running;
  display: inline-block;
  z-index: -1;
}

.s-journey .w-r .scroll-position-btn:hover img {
  animation-play-state: paused;
}

.s-journey .counter__single {
  text-align: center;
  margin-top: 80px;
}

.s-journey .counter__single:nth-of-type(1) {
  margin-top: 0px;
}

.s-journey .counter__single .odometer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.s-journey .counter__single .light-title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  color: var(--primary-color);
}

.s-journey .counter__single span {
  font-weight: 700;
}

.s-journey .counter__single .prefix {
  color: #65ff4b;
}

.s-journey .counter__single p {
  font-weight: 500;
  margin-top: 10px;
  text-align: start;
  color: white;
}

.h-empower {
  background-color: #120f23;
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.h-empower::after {
  position: absolute;
  top: 0px;
  transform: translateX(60%) rotate(45deg);
  right: 0px;
  content: "";
  width: 1280px;
  height: 520px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(84, 173, 255, 0.39);
  filter: blur(272px);
  z-index: -1;
}

.h-empower .h-empower-accordion {
  margin-top: 30px;
}

.h-empower .h-empower-single {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  padding: 30px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1254901961);
}

.h-empower .h-empower-single:nth-last-of-type(1) {
  padding-bottom: 0px;
  border-bottom: 0px;
}

.h-empower .h-empower-single .lefter {
  flex-grow: 1;
  max-width: 480px;
}

.h-empower .h-empower-single button {
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background: rgba(101, 255, 75, 0.062745098);
  color: var(--primary-color);
}

.h-empower .h-empower-single button span {
  transform: rotate(45deg);
}

.h-empower .h-empower-single h3 {
  color: white;
  font-weight: 500;
  text-transform: capitalize;
}

.h-empower .h-empower-single p {
  margin-top: 16px;
  display: none;
}

.h-empower .h-empower-single-active h3 {
  color: var(--primary-color);
}

.h-empower .h-empower-single-active button {
  color: #191b1a;
  background-color: var(--primary-color);
  filter: drop-shadow(0px 4px 23px rgba(101, 255, 75, 0.37));
}

.h-empower .h-empower-single-active button span {
  transform: rotate(0deg);
}

.h-s-case {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
}

.h-s-case::after {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translateX(-60%) rotate(-45deg);
  content: "";
  width: 1280px;
  height: 520px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(84, 173, 255, 0.39);
  filter: blur(130px);
  z-index: -1;
}

.h-s-case .h-s-case-wrapper {
  display: flex;
  height: 100%;
}

.h-s-case .h-s-case-single {
  position: relative;
  overflow: hidden;
  width: 16.6%;
  z-index: 1;
  min-height: 750px;
  max-height: 750px;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  cursor: pointer;
}

.h-s-case .h-s-case-single .thumb {
  position: relative;
  height: 100%;
}

.h-s-case .h-s-case-single .thumb img {
  width: 100%;
  min-height: 400px;
  height: 100%;
}

.h-s-case .h-s-case-single .h-case-content {
  position: absolute;
  bottom: 60px;
  left: 30px;
  right: 30px;
  display: flex;
  gap: 60px;
  align-items: flex-end;
  transition: var(--transition);
}

.h-s-case .h-s-case-single .h-case-content .case-title h2 {
  display: flex;
}

.h-s-case .h-s-case-single .h-case-content .case-title a {
  color: white;
  font-weight: 700;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  text-transform: capitalize;
}

.h-s-case .h-s-case-single .h-case-content p {
  color: white;
  margin-bottom: 40px;
}

.h-s-case .h-s-case-single .h-case-content .cta {
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
}

.h-s-case .h-s-case-single .h-case-content .cta::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(101, 255, 75, 0.3137254902);
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transition);
  z-index: -1;
}

.h-s-case .h-s-case-single .h-case-content .cta .arrow {
  position: relative;
  display: block;
  width: 130px;
}

.h-s-case .h-s-case-single .h-case-content .cta .arrow::before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 2px;
  width: calc(100% - 4px);
  background-color: #ffffff;
  transition: var(--transition);
}

.h-s-case .h-s-case-single .h-case-content .cta .arrow::after {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  right: -2px;
  top: 50%;
  transform: translateY(-48%);
  font-size: 24px;
  color: #ffffff;
  transition: var(--transition);
}

.h-s-case .h-s-case-single .h-case-content .cta:hover::before {
  right: 20px;
  background-color: rgba(101, 255, 75, 0.5019607843);
}

.h-s-case .h-s-case-single .h-case-content .cta:hover .arrow::before {
  background-color: var(--primary-color);
}

.h-s-case .h-s-case-single .h-case-content .cta:hover .arrow::after {
  color: var(--primary-color);
}

.h-s-case .h-s-case-single .h-c-continent {
  transform: translateX(200%);
  transition: var(--transition);
}

.h-s-case .h-s-case-single:hover .h-case-content .cta::before {
  right: 20px;
  background-color: rgba(101, 255, 75, 0.5019607843);
}

.h-s-case .h-s-case-single:hover .h-case-content .cta .arrow::before {
  background-color: var(--primary-color);
}

.h-s-case .h-s-case-single:hover .h-case-content .cta .arrow::after {
  color: var(--primary-color);
}

.h-s-case .h-s-case-single-active {
  width: 33.3%;
}

.h-s-case .h-s-case-single-active .h-case-content .cta .arrow::before {
  background-color: var(--primary-color);
}

.h-s-case .h-s-case-single-active .h-case-content .cta .arrow::after {
  color: var(--primary-color);
}

.h-s-case .h-s-case-single-active .h-c-continent {
  transform: translateX(0px);
}

.about-thumb img {
  width: 100%;
  border-radius: 20px;
  min-height: 300px;
}

.s-main::before {
  position: absolute;
  top: 0%;
  right: -100px;
  content: "";
  width: 420px;
  height: 420px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(143, 75, 255, 0.54);
  filter: blur(270px);
  z-index: -1;
}

.s-main .s-main__single .thumb {
  margin-bottom: 30px;
}

.s-main .s-main__single .thumb a {
  width: 100%;
  overflow: hidden;
  border-radius: 20px 130px 20px 20px;
}

.s-main .s-main__single .thumb a img {
  width: 100%;
  min-height: 260px;
  transition: var(--transition);
  border-radius: 20px 130px 20px 20px;
}

.s-main .s-main__single .content {
  padding: 0px 20px;
}

.s-main .s-main__single .content a {
  width: 100%;
}

.s-main .s-main__single:hover .thumb img {
  transform: scale(1.1);
}

.s-news .subscribe-form {
  max-width: 100%;
  margin-top: 0px;
}

.s-news .subscribe-form input {
  background-color: #292c32;
}

.s-news .subscribe-form button {
  background-image: linear-gradient(132deg, #65ff4b 0%, #46e695 100%);
  color: #1c1b1f;
}

.m-news-alter .news-alter__single {
  padding: 20px 20px 30px;
  background-color: var(--tertiary-color);
  border-radius: 20px;
  transition: var(--transition);
}

.m-news-alter .news-alter__single .thumb a {
  border-radius: 20px;
  box-shadow: 0px 0px 0px;
}

.m-news-alter .news-alter__single .thumb a img {
  border-radius: 20px;
}

.m-news-alter .news-alter__single h4 {
  margin-top: 20px;
  max-width: 380px;
}

.m-news-alter .news-alter__single h4 a {
  color: white;
  font-weight: 600;
}

.m-news-alter .news-alter__single h4 a:hover {
  color: var(--quaternary-color);
}

.m-news-alter .news-alter__single .meta span {
  color: #b1b0b6;
  transition: var(--transition);
}

.m-news-alter .news-alter__single .cta a {
  color: #b1b0b6;
}

.m-news-alter .news-alter__single:hover {
  background-color: var(--primary-color);
}

.m-news-alter .news-alter__single:hover h4 a {
  color: var(--quaternary-color);
}

.m-news-alter .news-alter__single:hover .meta span {
  color: var(--quaternary-color);
}

.m-news-alter .news-alter__single:hover .cta a {
  color: var(--quaternary-color);
}

.m-news-alter .news-alter__single:hover .blog__single-meta a,
.m-news-alter .news-alter__single:hover .blog__single-meta span {
  color: #4c4a59;
}

.b-details .b-details-meta {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.b-details .b-details-meta span {
  color: white;
  font-size: 16px;
}

.b-details .b-details-meta .time {
  position: relative;
  padding-left: 30px;
}

.b-details .b-details-meta .time::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: var(--primary-color);
  border-radius: 50%;
}

.b-details .b-comment {
  padding-top: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.1254901961);
}

.b-details .b-comment__wrapper {
  margin-top: 40px;
}

.b-details .b-comment-single {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 30px;
}

.b-details .b-comment-single .thumb {
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.b-details .b-comment-single .thumb img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.b-details .b-comment-single .content h5 {
  color: white;
  margin-bottom: 6px;
}

.b-details .b-comment-single .content .tertiary-text {
  color: white;
  margin-bottom: 24px;
}

.b-details .b-comment-single .content p:nth-last-of-type(1) {
  color: #b1b0b6;
}

.b-details .b-comment-single .content .content-meta {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.b-details .b-comment-single .content .content-meta button {
  font-size: 24px;
  color: var(--primary-color);
}

.b-details .b-comment-single .content .content-meta button:hover {
  color: white;
}

.b-details .b-comment-single .content .reply-box-wrapper {
  display: none;
}

.b-details .b-comment-single .content .reply-box {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 8px 12px;
  border-radius: 90px;
  margin-top: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.b-details .b-comment-single .content .reply-box img {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.b-details .b-comment-single .content .reply-box input {
  flex-grow: 1;
  padding: 8px 0px;
  background-color: transparent;
  font-size: 16px;
  width: calc(100% - 70px);
}

.b-details .b-comment-single .content .reply-box button {
  color: var(--primary-color);
  font-size: 20px;
}

.b-details .b-comment-single .content .reply-box button:hover {
  color: white;
}

.b-details .b-comment-single:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.b-details .b-comment-single:nth-last-of-type(1) .content .reply-box-wrapper {
  display: block;
}

.b-details .w-comment {
  padding: 30px;
  margin-top: 60px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.b-details .w-comment h3 {
  margin-bottom: 40px;
}

.b-details .w-comment .input-group {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}

.b-details .w-comment .input-group .input-single {
  width: calc(50% - 12px);
}

.b-details .w-comment .input-single input,
.b-details .w-comment .input-single textarea {
  background-color: inherit;
  padding: 16px 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  border-radius: 5px;
  max-height: 240px;
}

.b-details .b-details__sidebar {
  padding: 30px 20px;
  border-radius: 20px;
  background: #120f23;
}

.b-details .b-details-sidebar-single {
  margin-bottom: 60px;
}

.b-details .b-details-sidebar-single:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.b-details .b-search-bar .search-group {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  padding: 8px 12px 8px 24px;
  border: 1px solid rgba(255, 255, 255, 0.1882352941);
  border-radius: 10px;
}

.b-details .b-search-bar .search-group input {
  flex-grow: 1;
  width: calc(100% - 84px);
  padding: 12px 0px;
  background-color: inherit;
}

.b-details .b-search-bar .search-group button {
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #191b1a;
  border-radius: 10px;
  background-color: var(--primary-color);
}

.b-details .b-category ul {
  margin-top: 24px;
}

.b-details .b-category ul li {
  margin-bottom: 16px;
}

.b-details .b-category ul li:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.b-details .b-category a {
  display: flex;
  align-items: center;
  gap: 16px;
  color: #b1b0b6;
}

.b-details .b-category a span {
  color: var(--primary-color);
  font-size: 20px;
}

.b-details .b-category a:hover {
  color: var(--primary-color);
  padding-left: 6px;
}

.b-details .latest-post-wrapper {
  margin-top: 24px;
}

.b-details .latest-post-wrapper .latest-post-single {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.b-details .latest-post-wrapper .latest-post-single:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.b-details .latest-post-wrapper .latest-post-single .thumb {
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
}

.b-details .latest-post-wrapper .latest-post-single .thumb a {
  width: 100%;
}

.b-details .latest-post-wrapper .latest-post-single .thumb img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.b-details .latest-post-wrapper .latest-post-single .content .tertiary-text {
  margin-top: 16px;
  color: #b1b0b6;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
}

.b-details .latest-post-wrapper .latest-post-single .content .tertiary-text span {
  font-size: 20px;
}

.b-details .latest-post-wrapper .latest-post-single .content p a {
  color: white;
  font-weight: 400;
}

.b-details .latest-post-wrapper .latest-post-single .content p a:hover {
  color: var(--primary-color);
}

.b-details .b-tags-wrapper {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.b-details .b-tags-wrapper a {
  padding: 8px 12px;
  background-color: var(--primary-color);
  color: #191b1a;
  font-weight: 500;
  border-radius: 5px;
  font-size: 16px;
}

.shop .shop__sidebar {
  padding: 30px 20px;
  border-radius: 20px;
  background: #120f23;
}

.shop .shop__sidebar .shop-sidebar-single {
  margin-bottom: 60px;
}

.shop .shop__sidebar .shop-sidebar-single:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.shop .shop__sidebar .shop-sidebar-single h3 {
  margin-bottom: 24px;
}

.shop .check-group li {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.shop .check-group li:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.shop .check-group input {
  appearance: none;
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.4392156863);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.shop .check-group input::before {
  content: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  color: #191b1a;
}

.shop .check-group input:checked {
  background-color: var(--secondary-color);
}

.shop .check-group input:checked::before {
  content: "\e5ca";
  font-family: "Material Symbols Outlined";
  font-weight: 900;
}

.shop .check-group label {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.4392156863);
}

.shop .shop-price input {
  background-color: inherit;
  border: 0px;
  color: white;
}

.shop .shop-price .price-slider {
  height: 4px !important;
  border-radius: 4px !important;
  border: 0px !important;
}

.shop .shop-price .ui-slider-range {
  height: 4px !important;
  background-color: white !important;
  border-radius: 4px !important;
}

.shop .shop-price .ui-slider-handle {
  width: 8px;
  height: 16px;
  border-radius: 0px;
  border: 0px !important;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  margin: 0px !important;
}

.shop .shop-price .ui-slider-handle:nth-last-of-type(1) {
  margin-left: -8px !important;
}

.shop .shop-price .ui-state-focus,
.shop .shop-price .ui-state-hover {
  background: white !important;
  color: white !important;
  border: 0px !important;
}

.shop .shop-price label {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
}

.shop .shop-price label span {
  color: rgba(255, 255, 255, 0.4392156863);
}

.shop .shop-rating label {
  display: flex;
  align-items: center;
  gap: 4px;
}

.shop .shop-rating label i {
  color: rgba(255, 255, 255, 0.4392156863);
}

.shop .shop-rating .check-group li:nth-of-type(1) label i {
  color: var(--primary-color);
}

.shop .shop-rating .check-group li:nth-of-type(2) label i:nth-child(-n+4) {
  color: var(--primary-color);
}

.shop .shop-rating .check-group li:nth-of-type(3) label i:nth-child(-n+3) {
  color: var(--primary-color);
}

.shop .shop-rating .check-group li:nth-of-type(4) label i:nth-child(-n+2) {
  color: var(--primary-color);
}

.shop .shop-rating .check-group li:nth-of-type(5) label i:nth-child(-n+1) {
  color: var(--primary-color);
}

.shop .shop-search-bar .search-group {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  padding: 8px 12px 8px 24px;
  border: 1px solid rgba(255, 255, 255, 0.1882352941);
  border-radius: 10px;
}

.shop .shop-search-bar .search-group input {
  flex-grow: 1;
  width: calc(100% - 84px);
  padding: 12px 0px;
  background-color: inherit;
}

.shop .shop-search-bar .search-group button {
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #191b1a;
  border-radius: 10px;
  background-color: var(--primary-color);
}

.shop .shop__content .category__single {
  padding: 20px;
  background-color: #120f23;
  border-radius: 20px;
}

.shop .shop__content .category__single .thumb {
  margin-bottom: 20px;
  position: relative;
}

.shop .shop__content .category__single .thumb .thumb-img {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.shop .shop__content .category__single .thumb .thumb-img img {
  min-height: 180px;
  transition: var(--transition);
  border-radius: 10px;
  width: 100%;
}

.shop .shop__content .category__single .thumb .tag {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  background: #000000;
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 30px;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.shop .shop__content .category__single .thumb .tag img {
  border-radius: 10px;
}

.shop .shop__content .category__single .thumb .tag:hover {
  background-color: var(--primary-color);
  color: #191b1a;
}

.shop .shop__content .category__single .content {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.shop .shop__content .category__single .content a {
  font-weight: 600;
  color: white;
}

.shop .shop__content .category__single .content a:hover {
  color: var(--primary-color);
}

.shop .shop__content .category__single hr {
  margin: 20px 0px;
  background-color: rgba(255, 255, 255, 0.1254901961);
}

.shop .shop__content .category__single .meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
}

.shop .shop__content .category__single .meta-info {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.shop .shop__content .category__single .meta-info p {
  color: white;
}

.shop .shop__content .category__single .meta-info img {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop .shop__content .category__single .meta-review {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}

.shop .shop__content .category__single .meta-review i {
  font-size: 14px;
  color: #ffd584;
  cursor: pointer;
}

.shop .shop__content .category__single:hover .thumb-img img {
  transform: scale(1.1);
}

.shop .shop__content .cta {
  margin-top: 30px;
}

.shop .shop__content .cta .btn {
  width: 100%;
  justify-content: center;
}

.p-details .product-info {
  padding: 30px;
  border-radius: 20px;
  background: #120f23;
}

.p-details .large-product-img {
  margin-bottom: 24px;
}

.p-details .large-product-img img {
  width: 100%;
  border-radius: 20px;
  min-height: 300px;
}

.p-details .small-product-img img {
  width: 100%;
  border-radius: 10px;
  min-height: 100px;
}

.p-details .product__content {
  padding-left: 20px;
}

.p-details .product__content .product-discount {
  font-weight: 500;
  color: white;
  margin: 20px 0px;
}

.p-details .product__content .product-discount span {
  padding: 6px 16px;
  border-radius: 10px;
  background: rgba(101, 255, 75, 0.1254901961);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: var(--primary-color);
}

.p-details .product__content .product-m-price {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.p-details .product__content .product-m-price p {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}

.p-details .product__content .product-m-price p .deleted-price {
  text-decoration: line-through;
  color: rgba(255, 255, 255, 0.2509803922);
}

.p-details .product__content .product-m-price p .current-price {
  color: white;
}

.p-details .product__content .product-review {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
}

.p-details .product__content .product-review i {
  color: var(--primary-color);
  font-size: 20px;
}

.p-details .product__content .tags {
  margin-top: 20px;
}

.p-details .product__content .tags .tags-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.p-details .product__content .tags .tags-wrapper a {
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  background-color: #1f1c2f;
  border-radius: 40px;
}

.p-details .product__content .tags .tags-wrapper a:hover {
  background-color: var(--primary-color);
  color: #191b1a;
}

.p-details .product__content .product-cta {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}

.p-details .product__content .product-cta button {
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: #191b1a;
  font-size: 24px;
}

.p-details .p-details__tab {
  margin-top: 60px;
}

.p-details .p-details__tab .p-details__tab-btn {
  display: flex;
  align-items: center;
  row-gap: 24px;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1254901961);
}

.p-details .p-details__tab .p-details__tab-btn button {
  padding: 12px 20px;
  color: white;
  background-color: #120f23;
}

.p-details .p-details__tab .p-details__tab-btn .p-d-t-btn-active {
  background: linear-gradient(132deg, #65ff4b 0%, #46e695 100%);
  color: #191b1a;
}

.p-details .p-details__tab .p-details__tab-single {
  padding-top: 40px;
}

.p-details .p-details__tab .description-content p {
  margin-bottom: 24px;
}

.p-details .p-details__tab .description-content p:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.p-details .p-details__tab .review-content .group-c {
  display: flex;
  align-items: center;
  row-gap: 24px;
  background-color: #120f23;
  margin-bottom: 40px;
}

.p-details .p-details__tab .review-content .group-c input {
  flex-grow: 1;
  background-color: transparent;
  padding: 24px 16px;
}

.p-details .p-details__tab .review-content .group-c .btn {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-top: 26px;
  padding-bottom: 26px;
}

.p-details .p-details__tab .review-content .w-comment {
  padding: 30px;
  margin-top: 60px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.p-details .p-details__tab .review-content .w-comment h3 {
  margin-bottom: 40px;
}

.p-details .p-details__tab .review-content .w-comment .input-group {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}

.p-details .p-details__tab .review-content .w-comment .input-group .input-single {
  width: calc(50% - 12px);
}

.p-details .p-details__tab .review-content .w-comment .input-single input,
.p-details .p-details__tab .review-content .w-comment .input-single textarea {
  background-color: inherit;
  padding: 16px 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  border-radius: 5px;
  max-height: 180px;
}

.p-details .p-details__tab .b-comment-single {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 60px;
}

.p-details .p-details__tab .b-comment-single:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.p-details .p-details__tab .b-comment-single .thumb {
  width: 108px;
  min-width: 108px;
  height: 108px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid var(--primary-color);
}

.p-details .p-details__tab .b-comment-single .thumb img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.p-details .p-details__tab .b-comment-single .content h5 {
  color: white;
  margin-bottom: 6px;
}

.p-details .p-details__tab .b-comment-single .content .intro {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.p-details .p-details__tab .b-comment-single .content .intro span {
  color: #b1b0b6;
  margin: 0px;
}

.p-details .p-details__tab .b-comment-single .content .tertiary-text {
  color: white;
  margin-bottom: 24px;
}

.p-details .p-details__tab .b-comment-single .content p:nth-last-of-type(1) {
  color: #b1b0b6;
}

.p-details .p-details__tab .b-comment-single .content .content-meta {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.p-details .p-details__tab .b-comment-single .content .content-meta button,
.p-details .p-details__tab .b-comment-single .content .content-meta a {
  font-size: 16px;
  color: var(--primary-color);
}

.p-details .p-details__tab .b-comment-single .content .content-meta button:hover,
.p-details .p-details__tab .b-comment-single .content .content-meta a:hover {
  color: #b1b0b6;
}

.p-details .p-details__tab .b-comment-single .content .content-meta a {
  padding-left: 34px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #b1b0b6;
  position: relative;
}

.p-details .p-details__tab .b-comment-single .content .content-meta a:hover {
  color: var(--primary-color);
}

.p-details .p-details__tab .b-comment-single .content .content-meta a span {
  line-height: 0px;
}

.p-details .p-details__tab .b-comment-single .content .content-meta a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  width: 10px;
  height: 10px;
  background-color: var(--primary-color);
  border-radius: 50%;
  transform: translateY(-50%);
}

.p-details .p-details__tab .b-comment-single .content .reply-box-wrapper {
  display: none;
}

.p-details .p-details__tab .b-comment-single .content .reply-box {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 8px 12px;
  border-radius: 90px;
  margin-top: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.p-details .p-details__tab .b-comment-single .content .reply-box img {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-details .p-details__tab .b-comment-single .content .reply-box input {
  flex-grow: 1;
  padding: 8px 0px;
  background-color: transparent;
  font-size: 16px;
  width: calc(100% - 70px);
}

.p-details .p-details__tab .b-comment-single .content .reply-box button {
  color: var(--primary-color);
  font-size: 20px;
}

.p-details .p-details__tab .b-comment-single .content .reply-box button:hover {
  color: white;
}

.p-details .p-details__tab .b-comment-single:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.p-details .p-details__tab .b-comment-single:nth-last-of-type(1) .content .reply-box-wrapper {
  display: block;
}

.p-details .p-details__tab .author-content {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

.p-details .p-details__tab .author-content .thumb {
  width: 120px;
  min-width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-details .p-details__tab .author-content .thumb img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.p-details .p-details__tab .author-content h4 {
  color: white;
  font-weight: 700;
  margin-bottom: 12px;
}

.p-details .p-details__tab .author-content p {
  color: white;
  margin-bottom: 6px;
}

.p-details .p-details__tab .author-content p:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.m-contact .gaper {
  row-gap: 60px;
}

.m-contact .m-contact__single .thumb {
  width: 120px;
  min-width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgba(101, 255, 75, 0.1254901961);
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.m-contact .m-contact__single .content {
  text-align: center;
}

.m-contact .m-contact__single .content h3,
.m-contact .m-contact__single .content p {
  color: white;
}

.m-contact .m-contact__single .content h3 {
  margin-bottom: 20px;
  font-weight: 600;
}

.m-contact .m-contact__single .content p {
  margin-bottom: 12px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.m-contact .m-contact__single .content p:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.m-contact .m-contact__single .content p a {
  font-weight: 500;
}

.m-contact .input-single {
  margin-bottom: 24px;
}

.m-contact .input-single:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.m-contact .input-single input,
.m-contact .input-single textarea {
  background-color: inherit;
  padding: 20px 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  border-radius: 5px;
  max-height: 180px;
}

.m-contact .m-contact__form h3 {
  max-width: 550px;
  margin-bottom: 40px;
}

.m-contact .m-contact__thumb img {
  width: 100%;
  min-height: 300px;
}

.h-s-case-alter .h-s-case-single {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  cursor: pointer;
}

.h-s-case-alter .h-s-case-single .thumb {
  position: relative;
}

.h-s-case-alter .h-s-case-single .thumb a {
  width: 100%;
}

.h-s-case-alter .h-s-case-single .thumb img {
  width: 100%;
  min-height: 400px;
}

.h-s-case-alter .h-s-case-single .h-case-content {
  position: absolute;
  bottom: 60px;
  left: 30px;
  right: 30px;
  display: flex;
  gap: 60px;
  align-items: flex-end;
  transition: var(--transition);
}

.h-s-case-alter .h-s-case-single .h-case-content .case-title h2 {
  display: flex;
}

.h-s-case-alter .h-s-case-single .h-case-content .case-title a {
  color: white;
  font-weight: 700;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  text-transform: capitalize;
}

.h-s-case-alter .h-s-case-single .h-case-content p {
  color: white;
  margin-bottom: 40px;
}

.h-s-case-alter .h-s-case-single .h-case-content .cta {
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
}

.h-s-case-alter .h-s-case-single .h-case-content .cta::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(101, 255, 75, 0.3137254902);
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transition);
  z-index: -1;
}

.h-s-case-alter .h-s-case-single .h-case-content .cta .arrow {
  position: relative;
  display: block;
  width: 130px;
}

.h-s-case-alter .h-s-case-single .h-case-content .cta .arrow::before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 2px;
  width: calc(100% - 4px);
  background-color: #ffffff;
  transition: var(--transition);
}

.h-s-case-alter .h-s-case-single .h-case-content .cta .arrow::after {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  right: -2px;
  top: 50%;
  transform: translateY(-48%);
  font-size: 24px;
  color: #ffffff;
  transition: var(--transition);
}

.h-s-case-alter .h-s-case-single .h-case-content .cta:hover::before {
  right: 20px;
  background-color: rgba(101, 255, 75, 0.5019607843);
}

.h-s-case-alter .h-s-case-single .h-case-content .cta:hover .arrow::before {
  background-color: var(--primary-color);
}

.h-s-case-alter .h-s-case-single .h-case-content .cta:hover .arrow::after {
  color: var(--primary-color);
}

.h-s-case-alter .h-s-case-single:hover .h-case-content .cta::before {
  right: 20px;
  background-color: rgba(101, 255, 75, 0.5019607843);
}

.h-s-case-alter .h-s-case-single:hover .h-case-content .cta .arrow::before {
  background-color: var(--primary-color);
}

.h-s-case-alter .h-s-case-single:hover .h-case-content .cta .arrow::after {
  color: var(--primary-color);
}

.h-s-case-alter .case-filter__wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 20px;
}

.h-s-case-alter .case-filter__wrapper button:hover {
  color: var(--primary-color);
}

.h-s-case-alter .case-filter__wrapper button span {
  color: var(--primary-color);
}

.h-s-case-alter .case-filter__wrapper .active {
  color: var(--primary-color);
}

.h-s-case-alter .case-masonry {
  margin-bottom: -24px;
}

.h-s-case-alter .case-item {
  margin-bottom: 24px;
}

.case-details .case-details-meta {
  padding: 40px 0px 60px;
  border-bottom: 1px solid #1f1c2f;
  margin-bottom: 60px;
}

.case-details .case-details-meta .case-meta-right {
  display: flex;
  align-items: center;
  gap: 50px;
  row-gap: 16px;
  flex-wrap: wrap;
}

.case-details .case-details-meta .case-meta-right .tertiary-text {
  margin-bottom: 12px;
}

.case-details .case-details-meta .case-meta-right .line {
  width: 1px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.1882352941);
}

.case-details .case-d-content-left p {
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  padding-right: 7px;
  padding-top: 20px;
  letter-spacing: 2px;
}

.case-details .case-d-content-left .social {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.case-details .case-d-content-left .social a {
  background-color: transparent;
  color: #777581;
  font-size: 16px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.case-details .case-d-content-left .social a:hover {
  background-color: rgba(101, 255, 75, 0.1254901961);
  color: #65ff4b;
}

.case-details .case-d-content-right .text-group {
  margin-bottom: 60px;
}

.case-details .case-d-content-right .text-group:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.case-details .case-d-content-right .text-group h3 {
  margin-bottom: 30px;
}

.case-details .quote-group {
  padding: 30px 40px;
  border-radius: 20px;
  background-color: #100d21;
  display: flex;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 40px;
}

.case-details .quote-group q {
  margin-bottom: 16px;
  display: block;
}

.authentication {
  min-height: 100vh;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

/* .authentication::after {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  content: "";
  width: 570px;
  height: 570px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(208, 75, 255, 0.54);
  filter: blur(272px);
  z-index: -1;
} */

/* .authentication::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  background-image: url("../images/log-bg.png");
} */

.authentication .authentication__inner {
  padding: 100px 0px;
  position: relative;
  z-index: 2;
}

.authentication .intro p {
  margin-top: 24px;
  text-transform: capitalize;
  color: white;
}

.authentication .input-single {
  width: 100%;
  margin-bottom: 24px;
}

.authentication .input-single:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.authentication .input-single label {
  display: block;
  color: white;
  margin-bottom: 12px;
}

.authentication .input-single a {
  display: block;
  text-align: end;
  margin-top: 12px;
  font-size: 14px;
  color: #686673;
  font-weight: 400;
}

.authentication .input-single a:hover {
  color: var(--primary-color);
}

.authentication .input-single input {
  border-radius: 15px;
  border: 1px solid #4c4a59;
  background: #302e3f;
  backdrop-filter: blur(6.0781121254px);
  padding: 20px 20px 20px 60px;
  width: 100%;
  color: white;
}

.authentication .input-single input::placeholder {
  color: #a3a3a3;
}

.authentication .pass input {
  padding-right: 50px;
}

.authentication .ic-group {
  position: relative;
}

.authentication .ic-group span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  font-size: 24px;
  color: var(--primary-color);
}

.authentication .ic-group .show-pass {
  right: 20px;
  position: absolute;
  left: unset;
  z-index: 2;
  cursor: pointer;
}

.authentication form .btn {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 700;
}

.authentication .divider {
  margin: 30px 0px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.authentication .divider p {
  font-size: 16px;
  text-transform: capitalize;
  color: #686673;
}

.authentication .divider span {
  height: 1px;
  flex-grow: 1;
  background-color: #686673;
}

.authentication .auth-footer p {
  color: white;
  font-size: 16px;
  letter-spacing: 1px;
}

.authentication .auth-footer p a {
  color: var(--primary-color);
}

.authentication .auth-cta {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.authentication .auth-cta button {
  width: 60px;
  height: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
}

.auth-create::before {
  background-image: url("../images/sign-bg.png");
}

.cart-m {
  padding: 260px 0px 160px;
}

.cart-m .intro {
  margin-bottom: 80px;
}

.cart-m table {
  width: 100%;
}

.cart-m table tr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.cart-m table tr th {
  padding: 0px 12px;
  width: 17.5%;
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.cart-m table tr th:nth-of-type(1) {
  width: 30%;
  padding-left: 0px;
}

.cart-m table tr th:nth-last-of-type(1) {
  text-align: center;
}

.cart-m table thead {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1254901961);
}

.cart-m table tbody tr {
  padding: 30px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1254901961);
}

.cart-m table tbody tr td {
  width: 17.5%;
  padding: 0px 12px;
}

.cart-m table tbody tr td:nth-of-type(1) {
  padding-left: 0px;
  width: 30%;
}

.cart-m table tbody tr td:nth-last-of-type(1) {
  text-align: center;
}

.cart-m table tbody tr p,
.cart-m table tbody tr span,
.cart-m table tbody tr button {
  color: white;
  font-weight: 400;
}

.cart-m table .cart-single-product {
  display: flex;
  align-items: center;
  gap: 16px;
}

.cart-m table .cart-single-product .thumb {
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.cart-m table .cart-single-product .thumb img {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.cart-m table .cart-single-product p {
  color: white;
  margin-bottom: 12px;
}

.cart-m table .cart-single-product .item-review {
  display: flex;
  align-items: center;
  gap: 4px;
}

.cart-m table .cart-single-product .item-review i {
  font-size: 12px;
  color: var(--primary-color);
}

.cart-m table .measure {
  display: flex;
  align-items: center;
  gap: 12px;
}

.cart-m table .measure button {
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  background-color: rgba(255, 255, 255, 0.4392156863);
  color: #191b1a;
  font-size: 14px;
  font-weight: 700;
}

.cart-m table .item-quantity {
  min-width: 16px;
  color: var(--white);
  font-weight: 700;
  text-align: center;
}

.cart-m table .remove-item {
  font-size: 40px;
}

.cart-m table .remove-item span {
  color: var(--primary-color);
}

.cart-m .empty-cart-m {
  margin-top: 40px;
  display: none;
  letter-spacing: 1px;
}

.cart-m .cart__wrapper-footer {
  margin-top: 60px;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cart-m .cart__wrapper-footer form {
  flex-grow: 1;
  background-color: inherit;
  border: 1px solid rgba(255, 255, 255, 0.2509803922);
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  border-radius: 10px;
  max-width: 620px;
  justify-content: space-between;
}

.cart-m .cart__wrapper-footer form input {
  padding: 6px 16px;
  background-color: inherit;
  flex-grow: 1;
}

.cart-m .cart-content-cta {
  margin-top: 100px;
}

.cart-m .cart-content-cta a {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.cart-m .cart-content-cta a span {
  font-size: 24px;
}

.checkout-m {
  padding: 260px 0px 160px;
}

.checkout-m .intro h4 {
  margin-bottom: 30px;
  color: #86858f;
  font-weight: 600;
}

.checkout-m form .input-single input,
.checkout-m form .input-single textarea {
  width: 100%;
  background-color: var(--theme-bg);
  border: 1px solid rgba(255, 255, 255, 0.1882352941);
  border-radius: 10px;
  padding: 16px 20px;
  max-height: 230px;
  color: white;
}

.checkout-m form .input-single input::placeholder,
.checkout-m form .input-single textarea::placeholder {
  color: rgba(255, 255, 255, 0.3764705882);
}

.checkout-m form .input-group {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}

.checkout-m form .input-group .input-single {
  width: calc(50% - 12px);
  margin-bottom: 0px;
}

.checkout-m .checkout-m__content {
  padding: 30px;
  background-color: #1f1c2f;
  border-radius: 20px;
}

.checkout-m .checkout-m__content hr {
  margin-top: 30px;
  background-color: rgba(255, 255, 255, 0.1254901961);
  height: 1px;
}

.checkout-m .checkout-m__content .item-signle {
  padding: 30px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1254901961);
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checkout-m .checkout-m__content .item-signle .item-thumb {
  display: flex;
  align-items: center;
  gap: 16px;
}

.checkout-m .checkout-m__content .item-signle .item-thumb .thumb {
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.checkout-m .checkout-m__content .item-signle .item-thumb .thumb img {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.checkout-m .checkout-m__content .item-signle .item-thumb p {
  color: white;
  margin-bottom: 6px;
}

.checkout-m .checkout-m__content .item-signle .item-thumb p:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.checkout-m .checkout-m__content .item-qty {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  border: 1px solid rgba(255, 255, 255, 0.1254901961);
}

.checkout-m .checkout-m__content .item-qty span {
  color: white;
}

.checkout-m .checkout-m__content .item-overview {
  padding: 24px 0px;
}

.checkout-m .checkout-m__content .item-overview p {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;
  color: white;
}

.checkout-m .checkout-m__content .item-overview p:nth-last-of-type(1) {
  margin-bottom: 0px;
}

.checkout-m .checkout-m__content .item-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1254901961);
}

.checkout-m .checkout-m__content .item-total h3 {
  color: var(--primary-color);
  font-weight: 600;
}

.easy {
  overflow-x: clip;
  z-index: 1;
}

.easy::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: translateY(50%);
  content: "";
  width: 560px;
  height: 560px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(75, 255, 223, 0.24);
  filter: blur(230px);
  z-index: -1;
}

.easy--secondary .easy__cta {
  display: flex;
  align-items: center;
  gap: 24px;
}

.easy--secondary .easy__cta-single {
  padding: 40px 30px 0px;
  border-radius: 20px;
  border: 1px solid #302e3f;
  width: calc(50% - 12px);
  text-align: center;
  align-self: stretch;
}

.easy--secondary .easy__cta-single h4 {
  color: white;
}

.easy--secondary .easy__cta-single p {
  margin-top: 12px;
  color: var(--primary-color);
  margin-bottom: 30px;
}

.easy--secondary .easy__cta-single-alt {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
  overflow: hidden;
}

.easy--secondary .easy__cta-single-alt .thumb {
  position: absolute;
  top: -40px;
  right: -40px;
}

.c-slide {
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.c-slide::after {
  position: absolute;
  top: 0%;
  right: 5%;
  content: "";
  width: 570px;
  height: 570px;
  min-height: 300px;
  border-radius: 50%;
  background: rgba(219, 75, 255, 0.24);
  filter: blur(272px);
  z-index: -1;
}

.c-slide .slick-track {
  display: flex;
  align-items: center;
}

.c-slide .c-slide__single img {
  width: 100%;
  border-radius: 10px;
  border: 8px solid #dbdbdb;
  background: #fff;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.25);
  min-height: 240px;
}

/* ====
 --------- (5.0) all sections styles end ---------
 ==== */
/* ====
 --------- (6.0) responsive styles start ---------
 ==== */
@media only screen and (max-width: 1399.98px) {
  .banner .scroll-position-btn {
    width: 140px;
    min-width: 140px;
    height: 140px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
  }

  .banner .banner__large-slider__single img {
    max-width: 280px;
  }

  .banner .banner__small-slider__single img {
    max-width: 280px;
  }

  .banner-five h1,
  .banner-five .l-t {
    font-size: 100px;
    line-height: 110px;
    margin-top: -20px;
  }

  .banner-five .b-f-s-thumb img {
    max-width: 220px;
  }

  .banner-seven .scroll-position-btn {
    margin-right: auto;
  }

  .about-banner .scroll-position-btn {
    width: 120px;
    min-width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: unset;
    top: 130px;
    transform: translateY(0px);
  }

  .s-journey .counter__single {
    margin-top: 30px;
  }

  .l-t {
    font-size: 100px;
    line-height: 110px;
    margin-top: -20px;
  }

  .service-slider .service-slider__item .video-frame {
    width: 180px;
    min-width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .service-slider .service-slider__item .video-frame i {
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-slider-large-wrapper .slick-list {
    margin-top: -8px !important;
    margin-bottom: 0px !important;
  }

  .empower .content-wrapper {
    padding: 60px 40px;
    overflow: hidden;
    text-align: center;
  }

  .pricing--secondary .pricing__single {
    flex-direction: column;
  }

  .pricing--secondary .pricing__single .pricing__content {
    padding-left: 0px;
    padding-top: 30px;
    border-left: 0px;
    border-top: 1px solid #302e3f;
  }

  .pricing--secondary .pricing__single ul {
    margin-bottom: 40px;
  }

  .h-s-case .h-s-case-single {
    min-height: 500px;
    max-height: 500px;
  }

  .h-s-case .h-s-case-single .h-case-content {
    position: absolute;
    bottom: 40px;
    left: 20px;
    right: 20px;
    display: flex;
    gap: 20px;
    align-items: flex-end;
  }

  .h-s-case .h-s-case-single .h-case-content .case-title .light-title {
    font-size: 48px;
  }

  .h-s-case-alter .h-s-case-single .h-case-content {
    position: absolute;
    bottom: 40px;
    left: 20px;
    right: 20px;
    display: flex;
    gap: 20px;
    align-items: flex-end;
  }

  .h-s-case-alter .h-s-case-single .h-case-content .case-title .light-title {
    font-size: 48px;
  }
}

@media only screen and (max-width: 1199.98px) {

  p,
  th,
  td,
  li,
  input,
  textarea,
  select,
  label,
  blockquote,
  span {
    font-size: 16px;
    line-height: 26px;
  }

  .primary-text {
    font-size: 20px;
    line-height: 30px;
  }

  a,
  button {
    font-size: 16px;
    line-height: 24px;
  }

  h1,
  .light-title {
    font-size: 56px;
    line-height: 66px;
  }

  .light-title-sm,
  .light-title-xs {
    font-size: 40px;
    line-height: 50px;
  }

  h2 {
    font-size: 36px;
    line-height: 46px;
  }

  h3 {
    font-size: 30px;
    line-height: 40px;
  }

  .primary-navbar {
    padding: 30px 0px;
  }

  .primary-navbar .navbar__menu {
    display: none;
  }

  .s-details-single {
    padding: 80px 0px;
  }

  .s-details-contact {
    padding: 40px 20px;
  }

  .mobile-menu {
    position: fixed;
    inset: 0px;
    overflow: hidden;
    transition: var(--transition);
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    transition: var(--transition);
    z-index: 9999;
    transition: all 0.6s ease-in-out;
    max-width: 400px;
    background-color: var(--black);
  }

  .mobile-menu .mobile-menu__header {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    padding: 0px 40px;
    justify-content: space-between;
  }

  .mobile-menu .close-mobile-menu {
    font-size: 36px;
  }

  .mobile-menu .mobile-menu__wrapper {
    position: fixed;
    inset: 0px;
    max-width: 400px;
    background-color: var(--black);
    z-index: 9999;
    padding: 60px 0px;
    height: 100vh;
    overflow-y: auto;
    overflow-x: clip;
    display: flex;
    flex-direction: column;
    transition: 0.6s ease-in-out;
    transition-delay: 0.6s;
    gap: 60px;
    transform: translateY(100%);
    opacity: 1;
    visibility: visible;
  }

  .mobile-menu .mobile-menu__wrapper::-webkit-scrollbar {
    width: 0px;
  }

  .mobile-menu .navbar__list {
    flex-direction: column;
    border: 0px !important;
  }

  .mobile-menu .navbar__list>li>a,
  .mobile-menu .navbar__list>li button {
    border-bottom: 1px solid #414141;
  }

  .mobile-menu .navbar__list>li:nth-of-type(1) {
    border-top: 1px solid #414141;
  }

  .mobile-menu .navbar__item {
    width: 100%;
    transition: var(--transition);
  }

  .mobile-menu .navbar__item a,
  .mobile-menu .navbar__item button {
    color: var(--white);
    padding: 20px 80px 20px 40px;
    line-height: 1;
    font-size: 16px;
  }

  .mobile-menu .navbar__item a:hover,
  .mobile-menu .navbar__item button:hover {
    color: var(--primary-color);
  }

  .mobile-menu .navbar__item a::after,
  .mobile-menu .navbar__item button::after {
    transition: none;
  }

  .mobile-menu .nav-fade {
    transform: translateY(30px);
    opacity: 0;
    transition: all 0.7s ease-in-out !important;
  }

  .mobile-menu .navbar__item--has-children .navbar__dropdown-label::after {
    content: "+";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    width: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);
    border-left: 1px solid #414141;
  }

  .mobile-menu .navbar__item--has-children:hover .navbar__dropdown-label::after {
    transform: rotate(0deg);
  }

  .mobile-menu .navbar__item--has-children:hover>.navbar__dropdown-label-sub {
    color: var(--primary-color) !important;
  }

  .mobile-menu .navbar__item-active {
    color: var(--primary-color) !important;
  }

  .mobile-menu .navbar__item-active::after {
    content: "\f068" !important;
    font-family: "Font Awesome 6 Free" !important;
    font-weight: 900;
  }

  .mobile-menu .navbar__sub-menu {
    position: static;
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    width: 100%;
    max-width: 100%;
    padding: 0px;
    display: none;
    transition: none;
    background-color: transparent;
    border-radius: 0px;
  }

  .mobile-menu .navbar__sub-menu::before {
    content: none;
  }

  .mobile-menu .navbar__sub-menu a,
  .mobile-menu .navbar__sub-menu button {
    color: var(--white);
    padding: 20px 40px;
    font-size: 14px;
    border-bottom: 1px solid #414141;
  }

  .mobile-menu .navbar__sub-menu a::before,
  .mobile-menu .navbar__sub-menu button::before {
    content: none;
  }

  .mobile-menu .navbar__sub-menu li:nth-last-of-type(1) a,
  .mobile-menu .navbar__sub-menu li:nth-last-of-type(1) button {
    border-bottom: 1px solid #414141;
  }

  .mobile-menu .mobile-menu__options {
    padding: 0px 40px;
  }

  .mobile-menu .mobile-menu__options a,
  .mobile-menu .mobile-menu__options button {
    width: 100%;
  }

  .mobile-menu .mobile-menu__social {
    transition: var(--transition);
    padding: 0px 40px;
  }

  .mobile-menu .mobile-menu__social a {
    font-size: 20px;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-menu__backdrop {
    background-color: #302d2b;
    position: fixed;
    inset: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.6s ease-in-out;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;
  }

  .mobile-menu__backdrop-active {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
  }

  .show-menu {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
  }

  .show-menu .mobile-menu__wrapper {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
  }

  .show-menu .nav-fade {
    animation: navLinkFade 0.5s ease forwards;
    transition: all 0.7s ease-in-out !important;
  }

  .nav-fade-active {
    animation: navLinkFade 1s ease reverse !important;
  }

  .banner-five {
    background-size: 100px, 100px;
  }

  .banner-five h1,
  .banner-five .l-t {
    font-size: 70px;
    line-height: 80px;
    margin-top: -15px;
  }

  .banner-five .banner-five__thumb img {
    max-width: 300px;
    min-height: 300px;
  }

  .l-t {
    font-size: 70px;
    line-height: 80px;
    margin-top: -15px;
  }

  .service-slider .service-slider__item {
    width: 25vw;
    min-width: 25vw;
  }

  .pricing .pricing__single {
    margin-top: 0px;
  }

  .banner-three .scroll-position-btn {
    width: 120px;
    min-width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 4%;
    font-size: 30px;
  }

  .banner-three .banner-three__thumb img {
    max-width: 300px;
  }

  .revolution-f .revolution-f__single .case-study-hover {
    width: 120px;
    height: 160px;
  }

  .h-seven-text-wrapper .text-slider-large-rtl {
    margin-top: 40px;
  }

  .h-s-case .h-s-case-single {
    min-height: 450px;
    max-height: 450px;
  }

  .h-s-case .h-s-case-single .h-case-content .case-title .light-title {
    font-size: 36px;
  }

  .h-s-case-alter .h-s-case-single .h-case-content .case-title .light-title {
    font-size: 36px;
  }

  .footer-alter .footer-alter__inner {
    margin-top: 0px;
  }

  .footer-alter .footer-alter__inner .thumber {
    max-width: 230px;
  }
}

@media only screen and (max-width: 991.98px) {
  .primary-text {
    font-size: 18px;
  }

  h1,
  .light-title {
    font-size: 40px;
    line-height: 50px;
  }

  .light-title-sm,
  .light-title-xs {
    font-size: 30px;
    line-height: 40px;
  }

  h2 {
    font-size: 30px;
    line-height: 40px;
  }

  h3 {
    font-size: 24px;
    line-height: 34px;
  }

  h4 {
    font-size: 20px;
    line-height: 30px;
  }

  .cursor-inner,
  .cursor-outer {
    display: none;
  }

  .unset {
    max-width: 100%;
  }

  .section {
    padding: 100px 0px;
  }

  .section__cta {
    margin-top: 40px;
  }

  .section__content-cta {
    margin-top: 40px;
  }

  .progress-wrap {
    bottom: 10px;
    right: 15px;
    width: 40px;
    height: 40px;
  }

  .progress-wrap span {
    width: 30px;
    height: 30px;
  }

  .dir-rtl {
    direction: ltr;
  }

  .section__header--secondary .sub-title-two::before {
    left: 50%;
    transform: translateX(-50%);
  }

  .banner-three .banner-t-s-thumb {
    left: 4%;
  }

  .banner-three .banner-three__thumb img {
    max-width: 260px;
  }

  .banner-four {
    padding: 180px 0px 100px;
  }

  .banner-five {
    background-size: 70px, 80px;
    padding-top: 180px;
  }

  .banner-five h1,
  .banner-five .l-t {
    font-size: 50px;
    line-height: 60px;
    margin-top: -10px;
  }

  .banner-five .banner-five__thumb img {
    max-width: 200px;
    min-height: 300px;
  }

  .banner-five .scroll-position-btn {
    width: 120px;
    min-width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    right: 6%;
  }

  .banner-five .b-f-s-thumb {
    top: 70%;
    left: 4%;
  }

  .banner-five .b-f-s-thumb img {
    max-width: 180px;
  }

  .banner-seven {
    padding: 180px 0px 100px;
  }

  .banner-seven .scroll-position-btn {
    margin-left: 0px;
    margin-top: 60px;
  }

  .l-t {
    font-size: 50px;
    line-height: 60px;
    margin-top: -10px;
  }

  .cmn-banner {
    padding: 180px 0px 100px;
  }

  .text-slider-wrapper .text-slider .slick-list {
    margin: 0px -16px;
  }

  .text-slider-wrapper .text-slider .slick-slide {
    margin: 0px 16px;
  }

  .text-slider-wrapper .text-slider .text-slider__single {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  .service-slider {
    overflow-x: scroll !important;
  }

  .service-slider .service-slider__item {
    width: 280px;
    min-width: 280px;
  }

  .service-slider .service-slider__item .video-frame {
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  .service-slider .service-slider__item .video-frame i {
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .service-slider::-webkit-scrollbar {
    height: 5px;
    width: 100%;
  }

  .service-slider::-webkit-scrollbar-track {
    background-color: #cae6f7;
    border-radius: 5px;
  }

  .service-slider::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 5px;
  }

  .counter .counter__inner {
    flex-wrap: wrap;
    gap: 24px;
  }

  .counter .counter__single {
    width: calc(50% - 12px);
  }

  .review .review__slider-single {
    padding: 30px 20px;
  }

  .product-filter .grid-item-main {
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .product-filter .masonry-grid {
    margin-left: 0px;
    margin-right: 0px;
  }

  .revolution-f .revolution-f__single .thumb {
    padding: 10px;
    min-width: 80px;
    max-width: 80px;
  }

  .revolution-f .revolution-f__single .content {
    padding: 40px 20px;
  }

  .banner-six {
    padding: 180px 0px 100px;
  }

  .banner-six .banner-six__content {
    margin-bottom: 60px;
  }

  .s-journey .s-journey__content {
    display: flex;
    align-items: center;
    gap: 60px;
    row-gap: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .s-journey .s-journey__content .counter__single {
    margin: 0px;
  }

  .h-s-case .h-s-case-wrapper {
    flex-direction: column;
  }

  .h-s-case .h-s-case-single {
    width: 100%;
    height: 140px;
    min-height: unset;
  }

  .h-s-case .h-s-case-single .thumb img {
    max-height: 450px;
  }

  .h-s-case .h-s-case-single .h-case-content {
    bottom: unset;
    top: 40px;
    flex-direction: column;
    align-items: flex-start;
  }

  .h-s-case .h-s-case-single .h-case-content .case-title .light-title {
    font-size: 36px;
  }

  .h-s-case .h-s-case-single .h-case-content .case-title .light-title a {
    writing-mode: unset;
    transform: rotate(0deg);
  }

  .h-s-case .h-s-case-single .h-c-continent {
    transform: translateX(0px) translateY(200%);
  }

  .h-s-case .h-s-case-single-active {
    height: auto;
  }

  .h-s-case .h-s-case-single-active .h-case-content {
    top: unset;
    bottom: 40px;
  }

  .h-s-case .h-s-case-single-active .h-c-continent {
    transform: translateX(0px) translateY(0%);
  }

  .about-banner {
    padding: 180px 0px 100px;
  }

  .service-banner {
    padding: 180px 0px 100px;
  }

  .p-details .product__content {
    padding-left: 0px;
  }

  .case-details .case-details-meta .case-meta-right {
    gap: 40px;
  }

  .case-details .case-details-meta .case-meta-right .line {
    display: none;
  }

  .case-details .case-d-content-left p {
    writing-mode: unset;
    transform: unset;
    padding: 0px;
    margin-bottom: 20px;
    letter-spacing: 0px;
  }

  .case-details .case-d-content-left .social {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .authentication::before {
    width: 32%;
  }

  .cart-m {
    padding: 180px 0px 100px;
  }

  .cart-m .intro {
    margin-bottom: 50px;
  }

  .cart-m .cart-t-wrapper {
    overflow-x: scroll;
  }

  .cart-m .cart-t-wrapper::-webkit-scrollbar {
    height: 5px;
  }

  .cart-m .cart-t-wrapper::-webkit-scrollbar-track {
    background-color: #cae6f7;
    border-radius: 5px;
  }

  .cart-m .cart-t-wrapper::-webkit-scrollbar-button,
  .cart-m .cart-t-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 5px;
  }

  .cart-m .cart-t-wrapper table {
    min-width: 992px;
  }

  .cart-m .cart__wrapper-footer {
    margin-top: 40px;
  }

  .cart-m .cart-content-cta {
    margin-top: 60px;
  }

  .checkout-m {
    padding: 180px 0px 100px;
  }

  .footer .footer__content-cta {
    margin-top: 60px;
  }

  .footer .footer__nav {
    padding-top: 80px;
  }

  .footer .footer__nav-list ul {
    gap: 30px;
    row-gap: 16px;
  }

  .footer .footer__copyright-social a {
    font-size: 18px;
  }

  .footer-alter .footer-alter__inner {
    margin-top: 0px;
    background-size: 0px, 100%;
  }

  .footer-alter .footer-alter__inner .footer-alter__content {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-alter .footer-alter__inner .footer-alter__content h2 {
    max-width: 100%;
  }

  .footer-alter .footer-alter__inner .thumber {
    max-width: 230px;
  }

  .footer-cmn .footer__nav-list ul {
    gap: 30px;
    row-gap: 16px;
  }

  .lilu-review {
    padding-bottom: 250px;
  }
}

@media only screen and (max-width: 767.98px) {
  .sidebar-cart {
    width: 320px;
  }

  .search-popup .search-popup__group input,
  .search-popup .search-popup__group button {
    height: 60px;
    line-height: 60px;
  }

  .banner .banner__large-slider__single img {
    max-width: 180px;
    border-radius: 10px;
  }

  .banner .banner__small-slider {
    margin-bottom: -60px;
  }

  .banner .banner__small-slider__single img {
    max-width: 180px;
    border-radius: 10px;
  }

  .banner .scroll-position-btn {
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 55%;
  }

  .banner-two {
    padding: 180px 0px 100px;
  }

  .banner-two::before,
  .banner-two::after {
    content: none;
  }

  .banner-two .banner-two__thumb img {
    max-width: 35vw;
    min-width: 160px;
  }

  .banner-two .scroll-position-btn {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

  }

  .banner-three .banner-t-s-thumb {
    top: 23%;
  }

  .banner-three .banner-t-s-thumb img {
    max-width: 40px;
  }

  .banner-three .banner-three__thumb img {
    max-width: 180px;
  }

  .banner-three .scroll-position-btn {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 23%;
  }

  .banner-seven .banner-seven__group {
    flex-direction: column;
    align-items: flex-start;
  }

  .banner-seven .banner-seven__thumb {
    right: 20px;
  }

  .banner-seven .banner-seven__thumb img {
    min-width: 180px;
  }

  .about-banner .scroll-position-btn {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: unset;
    font-size: 24px;
    top: 130px;
    transform: translateY(0px);
  }

  .text-slider-large-wrapper .slick-list {
    margin: 0px -12px;
  }

  .text-slider-large-wrapper .slick-slide {
    margin: 0px 12px;
  }

  .text-slider-large-wrapper h2 {
    padding-left: 36px;
  }

  .text-slider-large-wrapper .text-stroke {
    padding-left: 6px;
  }

  .text-brief .t-br-three {
    justify-content: flex-start;
  }

  .video-s .video-s__inner {
    padding: 60px 20px;
  }

  .gaming .gaming__single-alt .thumb {
    max-width: 240px;
    min-width: 240px;
  }

  .news-alter .news-alter-single {
    flex-direction: column;
    align-items: flex-start;
  }

  .news-alter .news-alter-single .thumb {
    min-width: 100%;
    max-width: 100%;
  }

  .news-alter .news-alter-single .thumb img {
    max-width: 100%;
    max-height: 100%;
  }

  .h-s-poster .news-alter__single .thumb .tags {
    top: 24px;
    left: 24px;
  }

  .h-s-poster .news-alter__single .content {
    bottom: 24px;
    left: 24px;
    right: 24px;
  }

  .b-details .b-comment-single {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 20px;
  }

  .b-details .b-comment-single .content .reply-box {
    gap: 12px;
  }

  .b-details .b-comment-single .content .reply-box input {
    width: calc(100% - 40px);
  }

  .b-details .w-comment {
    padding: 30px 20px;
  }

  .b-details .w-comment .input-group {
    flex-direction: column;
  }

  .b-details .w-comment .input-group .input-single {
    width: 100%;
  }

  .p-details .product-info {
    padding: 30px 20px;
  }

  .p-details .p-details__tab .group-c {
    flex-direction: column;
    align-items: flex-start !important;
    padding: 30px 20px;
  }

  .p-details .p-details__tab .group-c input {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1882352941);
  }

  .p-details .p-details__tab .group-c .btn {
    border-radius: 10px !important;
  }

  .p-details .p-details__tab .review-content .w-comment {
    padding: 30px 20px;
  }

  .p-details .p-details__tab .review-content .input-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .p-details .p-details__tab .review-content .input-group .input-single {
    width: 100% !important;
  }

  .p-details .p-details__tab .b-comment-single {
    flex-direction: column;
    align-items: flex-start;
  }

  .case-details .case-d-content-right .text-group {
    margin-bottom: 40px;
  }

  .case-details .case-d-content-right .text-group:nth-last-of-type(1) {
    margin-bottom: 0px;
  }

  .case-details .case-d-content-right .text-group h3 {
    margin-bottom: 20px;
  }

  .case-details .case-d-content-right .img-group {
    flex-direction: column;
    margin: 40px 0px;
  }

  .case-details .case-d-content-right .img-group .poster-img {
    width: 100%;
  }

  .case-details .case-d-content-right .quote-group {
    flex-direction: column;
    padding: 30px 20px;
  }

  .authentication::before {
    content: none;
  }

  .authentication .authentication__inner {
    padding: 80px 0px;
  }

  .checkout-m form .input-group {
    flex-direction: column;
  }

  .checkout-m form .input-group .input-single {
    width: 100%;
  }

  .checkout-m form .input-single input,
  .checkout-m form .input-single textarea {
    max-height: 180px;
  }

  .checkout-m .checkout-m__content {
    padding: 30px 20px;
  }

  .footer .footer__content h2 span::before {
    height: 50%;
  }

  .footer-alter .footer-alter__inner {
    padding: 80px 20px;
  }

  .footer-alter .footer-alter__inner .footer-alter__content h2 {
    max-width: 100%;
  }

  .footer-alter .footer-alter__inner .offcanvas-info__form {
    width: 100%;
  }

  .footer-alter .footer-alter__inner .subscribe-form {
    max-width: 100%;
  }
}

@media only screen and (max-width: 575.98px) {

  h1,
  .light-title {
    font-size: 26px;
    line-height: 40px;
  }

  .light-title-sm,
  .light-title-xs {
    font-size: 24px;
    line-height: 34px;
  }

  h2 {
    font-size: 24px;
    line-height: 34px;
  }

  h3 {
    font-size: 20px;
    line-height: 30px;
  }

  h4 {
    font-size: 18px;
    line-height: 28px;
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
  }

  .img-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .img-group * {
    width: 100%;
  }

  .primary-navbar .navbar {
    gap: 20px;
  }

  .primary-navbar .navbar__logo {
    max-width: 110px;
  }

  .primary-navbar .navbar__mobile-options {
    gap: 16px;
  }

  .primary-navbar .navbar__mobile-options button,
  .primary-navbar .navbar__mobile-options .prevent-cart {
    font-size: 18px;
  }

  .primary-navbar .navbar__options {
    gap: 16px;
  }

  .mobile-menu {
    max-width: 320px;
  }

  .mobile-menu .mobile-menu__wrapper {
    max-width: 320px;
  }

  .mobile-menu .mobile-menu__header,
  .mobile-menu .mobile-menu__options,
  .mobile-menu .mobile-menu__social {
    padding: 0px 24px;
  }

  .mobile-menu .mobile-menu__social {
    gap: 16px;
  }

  .mobile-menu .mobile-menu__social a {
    width: 46px;
    min-width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  .mobile-menu .navbar__item a,
  .mobile-menu .navbar__item button {
    font-size: 14px;
    padding: 20px 70px 20px 24px;
  }

  .offcanvas-info .offcanvas-info__inner {
    padding: 60px 24px;
    gap: 40px;
  }

  .offcanvas-info .offcanvas-info__intro h4 {
    max-width: 280px;
  }

  .banner h1 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .banner .banner-thumb-one {
    top: 100px;
  }

  .banner-five {
    background-size: 70px, 80px;
    padding-top: 180px;
  }

  .banner-five h1,
  .banner-five .l-t {
    font-size: 30px;
    line-height: 40px;
    margin-top: -10px;
  }

  .banner-five .banner-five__thumb img {
    max-width: 160px;
    min-height: 240px;
  }

  .banner-five .scroll-position-btn {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    right: 2%;
  }

  .banner-five .b-f-s-thumb {
    top: 90%;
    left: 2%;
  }

  .banner-five .b-f-s-thumb img {
    max-width: 80px;
  }

  .l-t {
    font-size: 30px;
    line-height: 40px;
    margin-top: -10px;
  }

  .text-slider-large-wrapper h2 {
    font-size: 30px !important;
  }

  .counter .counter__inner {
    row-gap: 40px;
  }

  .counter .counter__single {
    width: 100%;
  }

  .counter .counter__single p {
    margin-top: 12px;
  }

  .overview .overview__single {
    padding: 30px 20px;
  }

  .pricing .section__header button {
    padding: 8px 20px;
  }

  .pricing .pricing__single {
    padding: 40px 20px;
  }

  .easy--secondary .easy__cta {
    flex-direction: column;
  }

  .easy--secondary .easy__cta .easy__cta-single {
    width: 100%;
  }

  .gaming .gaming__single-alt {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 20px;
  }

  .gaming .gaming__single-alt .thumb {
    max-width: 100%;
    min-width: 100%;
  }

  .gaming .gaming__single-alt .thumb a {
    width: 100%;
  }

  .gaming .gaming__single-alt .thumb a img {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .category .category-overview__single a {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  .h-empower .h-empower-single {
    flex-direction: column;
    align-items: flex-start;
  }

  .h-empower .h-empower-single .lefter {
    max-width: 100%;
  }

  .h-s-case .h-s-case-single {
    height: 120px;
  }

  .h-s-case .h-s-case-single .h-case-content .case-title .light-title {
    font-size: 28px;
  }

  .h-s-case .h-s-case-single-active {
    height: auto;
  }

  .h-s-case .h-s-case-single-active .h-case-content {
    top: unset;
    bottom: 40px;
  }

  .checkout-m .checkout-m__content .item-signle .item-thumb {
    gap: 12px;
  }

  .checkout-m .checkout-m__content .item-signle .item-thumb .thumb {
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkout-m .checkout-m__content .item-qty {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
  }

  .checkout-m .checkout-m__content .item-qty span {
    font-size: 12px;
  }

  .footer .footer__content h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;
    font-size: 24px;
  }

  .footer .footer__content-cta {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 374.98px) {
  .b-details .latest-post-wrapper .latest-post-single {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .b-details .latest-post-wrapper .latest-post-single:nth-last-of-type(1) {
    margin-bottom: 0px;
  }

  .b-details .b-search-bar .search-group button {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 1700px) {
  .primary-navbar .container {
    max-width: 1660px;
  }

  .primary-navbar .navbar {
    gap: 120px;
  }
}

@media only screen and (min-width: 1400px) {
  .text-slider-large-wrapper h2 {
    font-size: 160px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 768px) {
  .banner .banner-anime {
    --transformY: -50%;
  }

  .banner .banner-anime::before {
    transform: translateY(var(--transformY)) translateX(-50%) rotate(-5deg);
  }

  .footer .footer__content h2 span {
    --opacity: 0.3;
    --transformY: -250%;
  }

  .footer .footer__content h2 span::before {
    content: "";
    transform: translateX(-50%) translateY(var(--transformY));
    opacity: var(--opacity);
  }

  .footer .footer-thumb-one img {
    transform: rotate(10deg) translateX(300px);
    opacity: 0.5;
  }

  .footer .footer-thumb-two img {
    opacity: 0.5;
    transform: rotate(-154deg) translateY(-200px);
  }
}

/* ====
 --------- (6.0) responsive styles end ---------
 ==== */


.ms-custom {
  margin-left: 2.4rem !important;

}

.chat-content {
  all: initial;
}

.chat-content li p {
  display: inline
}


.chat-content table,
th,
td {
  border: 1px solid;
  padding: 5px;
}

.chat-content p {
  /* line-height: 24px; */
  line-height: 1.8rem;
}

.login-section {
  background-color: #1ea57a;
}