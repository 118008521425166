/* You can add global styles to this file, and also import other style files */

/* @font-face {
    font-display: swap;
    font-family: Signifier;
    font-style: normal;
    font-weight: 400;
    src: url(https://cdn.oaistatic.com/_next/static/media/signifier-light.c131ea48.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: Signifier;
    font-style: italic;
    font-weight: 400;
    src: url(https://cdn.oaistatic.com/_next/static/media/signifier-light-italic.ae129369.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: Signifier;
    font-style: normal;
    font-weight: 700;
    src: url(https://cdn.oaistatic.com/_next/static/media/signifier-bold.3a11015f.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: Signifier;
    font-style: italic;
    font-weight: 700;
    src: url(https://cdn.oaistatic.com/_next/static/media/signifier-bold-italic.52d511e3.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: taxbotgpt;
    font-style: normal;
    font-weight: 400;
    src: url(https://cdn.oaistatic.com/_next/static/media/soehne-buch.13189857.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: taxbotgpt;
    font-style: italic;
    font-weight: 400;
    src: url(https://cdn.oaistatic.com/_next/static/media/soehne-buch-kursiv.1052965d.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: taxbotgpt;
    font-style: normal;
    font-weight: 500;
    src: url(https://cdn.oaistatic.com/_next/static/media/soehne-kraftig.204b461c.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: taxbotgpt;
    font-style: italic;
    font-weight: 500;
    src: url(https://cdn.oaistatic.com/_next/static/media/soehne-kraftig-kursiv.a0040f3f.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: taxbotgpt;
    font-style: normal;
    font-weight: 600;
    src: url(https://cdn.oaistatic.com/_next/static/media/soehne-halbfett.977f1845.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: taxbotgpt;
    font-style: italic;
    font-weight: 600;
    src: url(https://cdn.oaistatic.com/_next/static/media/soehne-halbfett-kursiv.cb37a814.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: taxbotgpt Mono;
    font-style: normal;
    font-weight: 400;
    src: url(https://cdn.oaistatic.com/_next/static/media/soehne-mono-buch.de677af1.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: taxbotgpt Mono;
    font-style: normal;
    font-weight: 700;
    src: url(https://cdn.oaistatic.com/_next/static/media/soehne-mono-halbfett.b082e8e2.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: taxbotgpt Mono;
    font-style: italic;
    font-weight: 400;
    src: url(https://cdn.oaistatic.com/_next/static/media/soehne-mono-buch-kursiv.8eeb89d0.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: taxbotgpt Circle;
    font-style: normal;
    font-weight: 400;
    src: url(https://cdn.oaistatic.com/_next/static/media/soehne-circle-buch.d446e5e9.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: taxbotgpt Circle;
    font-style: normal;
    font-weight: 600;
    src: url(https://cdn.oaistatic.com/_next/static/media/soehne-circle-halbfett.9eb377ad.woff2) format("woff2")
} */

body {
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}

.markdown {
    max-width: none;
    text-size-adjust: 100%;
    font-feature-settings: normal;
    -webkit-tap-highlight-color: transparent;
    font-family: taxbotgpt, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-variation-settings: normal;
    line-height: 1.5;
    tab-size: 4;
}

.markdown h1 {
    font-weight: 600
}

.markdown h1:first-child {
    margin-top: 0
}

.markdown h2 {
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 2rem
}

.markdown h2:first-child {
    margin-top: 0
}

.markdown h3 {
    margin-bottom: .5rem;
    margin-top: 1rem;
    font-size: 1.1rem;
    color: #111;
    font-weight: 600;
}

.markdown h3:first-child {
    margin-top: 0
}

.markdown h4 {
    font-weight: 400;
    margin-bottom: .5rem;
    margin-top: 1rem
}

.markdown h4:first-child {
    margin-top: 0
}

.markdown h5 {
    font-weight: 600
}

.markdown h5:first-child {
    margin-top: 0
}

.markdown blockquote {
    --tw-border-opacity: 1;
    border-color: rgba(155, 155, 155, var(--tw-border-opacity));
    border-left-width: 2px;
    line-height: 1.5rem;
    margin: 0;
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-top: .5rem
}

.markdown blockquote>p {
    margin: 0
}

.markdown blockquote>p:after,
.markdown blockquote>p:before {
    display: none
}

.ms-custom strong { color: #efefef; font-weight: 500;}
.chat-icons a .bi { color: #939393; }
.chat-icons a:hover .bi { color: #f9c613;}

.ms-custom ul li::marker {
    list-style-type:disc;
    position: absolute;
    left: 0;
    color: #fff;
}

.dropdown-menu-dark {
    color: #212121;
    background-color: #212121;
    border-color: rgba(0, 0, 0, .15);
    border: #373636 solid 1px;
}

.dropdown-menu li a { font-size: 1rem;}
.dropdown-menu li a small {
    font-size: 0.8rem;
    color: #787878;
    line-height: 18px;
    display: inline-block;
    width: 100%;
    margin-top: 3px;
}

.right-sidebar .dropdown .dropdown-menu {
	width:350px;
}

.upgradeplan {
    cursor: pointer;
}

.dropdown-menu-dark .dropdown-item:focus, .dropdown-menu-dark .dropdown-item:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(0 0 0);
}

.dropdown .dropdown-menu {
    width: 250px;
}

.dropdown .dropdown-menu li a.dropdown-item {
    white-space: normal;
}

.dropdown-menu-dark .dropdown-divider {
    border-color: #3a3a3a;
}


.btn-plan {
    background-color: rgb(20 235 158);
    border-radius: 50px;
    font-size: 0.8rem;
    color: rgb(0 0 0);
    font-weight: 600;
    padding: 5px 10px;
    width: 100%;
    margin-top: 15px;
    border: none;
}

.plan-header h4 { font-size: 1rem !important;}


/*** theme change style ***/

.right-sidebar .dropdown .dropdown-menu { padding: 0;}

.right-sidebar {
    background-color: #212121;
}

.light-theme .right-sidebar {
    background-color: #fff;
}

.light-theme .dropdown h4 {
    color: #212121 !important;
}

.light-theme .dropdown h4 span { color: #212121;}

.light-theme .dropdown-menu-dark {
    color: #212121;
    background-color: #ffffff;
    border-color: rgba(0, 0, 0, .15);
    border: #d0d0d0 solid 1px;
}

.light-theme .dropdown-menu-dark .dropdown-item {
    color: #000000;
}

.light-theme .dropdown-menu-dark .dropdown-item:hover {
    color: #000000;
    background-color: #f7f7f7;
}

.light-theme .dropdown-menu-dark .dropdown-item:hover small {
    color: #000000;
}

.light-theme .sidebar-footer { background: rgb(239 239 239) !important; }
.light-theme .sidebar-footer a span { color: #000 !important;}
.light-theme .chat:hover { background-color:rgb(229 229 229) !important; color: #000 !important; }
.light-theme .fa-regular { color: #000 !important;}
.light-theme .logo span { color: #000 !important;}
.light-theme .profile-dropdown .dropdown-menu-dark .dropdown-item {
    color: #333333 !important;
}
.light-theme .dropdown-menu-dark .dropdown-divider {
    border-color: rgb(235 235 235) !important;
}

.light-theme p, .light-theme th, .light-theme td, .light-theme li, .light-theme span {
    color: #0d0d0d;
    line-height: 1.8rem;
}

.light-theme li::marker { color: #000; font-weight: 600;}

.light-theme .ms-custom strong {
    color: #000000;
    font-weight: 600;
}
.light-theme .markdown h3 { color: #000000;
    font-weight: 600; }

.light-theme .sidebar { background: #efefef !important; }

.light-theme .chat { color: #000; }

.light-theme .themechange-btn {
    background-color: #e8e8e8 !important;
    color: #000;
}

.light-theme .ms-custom ul li::marker {
    color: #000;
}


.light-theme .nav-link {
    color: #000000 !important;
}

.light-theme .nav-link.active {
    color: #000000 !important;
    background: #d7d7d7 !important;
}

.light-theme .list-group-flush > .light-theme .list-group-item {
    color: #000 !important;
    border-bottom: #ebebeb solid 1px !important;
}

.light-theme .bg-dark {
    background-color: #ffffff !important;
}

.light-theme .list-group-flush > .list-group-item {
    color: #000 !important;;
    border-bottom: #e4e4e4 solid 1px !important;;
}

.light-theme .btn-setting {
    border: #636363 solid 2px !important;;
    background: #636363 !important;;
    color: #ffffff !important;;
}

.light-theme .plan-col {
    border: #f6f6f6 solid 1px !important;;
    background-color: #f6f6f6 !important;;
}

.light-theme .plan-header h4 {
    font-size: 1rem !important;
    color: #000 !important;
}

.light-theme .btn-plan {
    background-color: rgb(13 153 103) !important;;
    color: rgb(255 255 255) !important;;
}
.light-theme .plan-col span {
    color: #00000090 !important;;
    font-weight: 600 !important;;
}

.light-theme .bg-dark .btn-info {
    color: #000 !important;;
}

.light-theme .suggestion-col a {
    border: #b6b6b6 solid 1px;
    color: #000;
}

.light-theme .fa-bars, .light-theme .fa-times {
    color: #000;
}

.light-theme .support-title { color: #000 !important;}

.light-theme .search-box input {
    color: #000;
}
.dropdown-menu li a small { color: #fff;}

.light-theme .markdown { color: #000;}

.light-theme .dropdown-menu li a small {
    color: #000;
}

.light-theme .dropdown-menu li a.version_selected {
    color: #fff;
}

.light-theme .dropdown-menu li a.version_selected small {
    color: #fff;
}

.light-theme .plan-header h4.text-white {
    font-size: 1rem !important;
    color: #000 !important;
}

.light-theme .plan-col .plan-col-header h4 { color: #000;}
.dark-theme .plan-col .plan-col-header h4 { color: #fff;}

.whatsapp-sticky {
    position: fixed;
    bottom: 50px;
    left: 20px;
    z-index: 999;
}

.whatsapp-sticky img {
    width: 50px;
}

.cursor{
    cursor: pointer;
}